import { useEffect, useRef, useState, lazy, Suspense } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
// import { useDispatch, useSelector } from "react-redux";

import {
  Backdrop,
  Box,
  Button,
  Checkbox,
  Divider,
  Fade,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Modal,
  Select,
  Slider,
  Switch,
  TextField,
  Typography,
  useMediaQuery,
  InputLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  RadioGroup,
  Radio,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  ListItemIcon,
} from "@mui/material";
import axios from "axios";
import BedOutlinedIcon from "@mui/icons-material/BedOutlined";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import AddIcon from "@mui/icons-material/Add";
import EuroSymbolIcon from "@mui/icons-material/EuroSymbol";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import { styled } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import RemoveIcon from "@mui/icons-material/Remove";
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";


// import {
//   getAllAmenities,
//   getAllHouseRules,
//   getAllPropertyType,
//   getAllCountries,
//   getTopAmenities,
//   postForm,
// } from "../../Redux/feature/listPropertySlice";

// import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";

const BsFillPersonFill = lazy(() =>
  import("react-icons/bs").then((module) => ({
    default: module.BsFillPersonFill,
  }))
);

const LuBedSingle = lazy(() =>
  import("react-icons/lu").then((module) => ({ default: module.LuBedSingle }))
);

const LuBedDouble = lazy(() =>
  import("react-icons/lu").then((module) => ({ default: module.LuBedDouble }))
);

const LuSofa = lazy(() =>
  import("react-icons/lu").then((module) => ({ default: module.LuSofa }))
);

const BiBed = lazy(() =>
  import("react-icons/bi").then((module) => ({ default: module.BiBed }))
);

const GiBunkBeds = lazy(() =>
  import("react-icons/gi").then((module) => ({ default: module.GiBunkBeds }))
);

const IoMdCloseCircleOutline = lazy(() =>
  import("react-icons/io").then((module) => ({
    default: module.IoMdCloseCircleOutline,
  }))
);

const BsDot = lazy(() =>
  import("react-icons/bs").then((module) => ({ default: module.BsDot }))
);

const steps = [
  "Step 1",
  "Step 2",
  "Step 3",
  "Step 4",
  "Step 5",
  "Step 6",
  "Step 7",
  "Step 8",
  "Step 9",
  "Step 10",
  "step 11",
];

const productItem = [
  {
    image:
      "https://img.freepik.com/free-photo/cheerful-young-man-posing-isolated-grey_171337-10579.jpg?w=740&t=st=1697651822~exp=1697652422~hmac=d2eeacd6c8113e66816390d5a8b932399924d2352f7138cdef0f840627077adc",
    name: "Jan Stiedemann",
    descripition:
      "Listing my property with Voyagers Tales was a game-changer. The support, marketing, and professional touch exceeded my expectations. Thrilled to be part of this thriving community.",
    rating: 4.5,
  },
  {
    image:
      "https://img.freepik.com/premium-photo/profile-view-self-confident-bearded-man-looking-with-serious-expression-unsmiling-determined-business-man-indoor-studio-shot-isolated-black-background_2221-8603.jpg",
    name: "Victor Henderson",
    descripition:
      "Our stay with Voyagers Tales was exceptional. The property was exactly as described, and the booking process was effortless. We look forward to more adventures with them!",
    rating: 5,
  },
  {
    image:
      "https://media.istockphoto.com/id/512735004/photo/portrait-of-a-young-beautiful-woman.webp?b=1&s=170667a&w=0&k=20&c=ukfWAogvEFKXYdD6OmNBYyhMf2H3Gnb1qiQnH2fRe3I=",
    name: "Alina",
    descripition:
      "Joining Voyagers Tales was a decision I'll never regret. Their dedication to my property's success and the vibrant community of property owners make it an unbeatable choice.",
    rating: 4,
  },
  {
    image:
      "https://images.unsplash.com/photo-1487412720507-e7ab37603c6f?auto=format&fit=crop&q=80&w=1471&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    name: "Rosy Palmer",
    descripition:
      "Our recent stay with Voyagers Tales was fantastic. The property was spotless, and the seamless booking process made our trip stress-free. Can't wait for our next adventure!",
    rating: 5,
  },
  {
    image:
      "https://images.unsplash.com/photo-1487412720507-e7ab37603c6f?auto=format&fit=crop&q=80&w=1471&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    name: "Rosy Palmer",
    descripition:
      "Our recent stay with Voyagers Tales was fantastic. The property was spotless, and the seamless booking process made our trip stress-free. Can't wait for our next adventure!",
    rating: 5,
  },
];

const MAX_IMAGE_COUNT = 30;
const MAX_IMAGE_SIZE = 300 * 1024;

const EditProperty = () => {
  const xs = useMediaQuery("(max-Width: 600px)");
  const sm = useMediaQuery("(min-width: 601px) and (max-width: 900px)");
  const location = useLocation();
  const { step, propertyData } = location.state || {};
  const localizer = momentLocalizer(moment);
  const [events, setEvents] = useState([]);
  const bookedDates = propertyData?.room_available_date?.dates?.map((date) =>
    moment(date, "DD-MMM-YYYY").toDate()
  ) || [];

  // Create booked events based on bookedDates
  const bookedEvents = bookedDates?.map((date, index) => ({
    title: "Booked",
    start: date,
    end: date,
    allDay: true,
    id: `booked-${index}`, // Unique ID for booked events
  })) || [];

  const isDateBooked = (date) => {
    // Check if the date is in the bookedDates array
    return bookedDates?.some(
      (bookedDate) => bookedDate?.getTime() === date?.getTime()
    );
  };
  const dayPropGetter = (date) => {
    const now = new Date();
    if (date < now.setHours(0, 0, 0, 0)) {
      return {
        style: {
          backgroundColor: "#f0f0f0", // Light grey background for past dates
        },
      };
    }

    if (isDateBooked(date)) {
      return {
        style: {
          backgroundColor: "#ffcccb", // Light red background for booked dates
        },
      };
    }

    return {};
  };

  // const subamenityIds = [];

  // useEffect(() => {
  //   // Function to extract subamenities' ids
  //   const extractSubamenityIds = () => {

  //     propertyData?.amenities?.forEach(amenity => {
  //       // Check if subamenities exist and loop through them
  //       if (amenity.subamenities && Array.isArray(amenity.subamenities)) {
  //         amenity.subamenities.forEach(subamenity => {
  //           subamenityIds.push(subamenity.id); // Push subamenity id to the array
  //         });
  //       }
  //     });
  //   };

  //   extractSubamenityIds(); // Call the function on component mount
  // }, [propertyData]);
  const [selectProperty, setSelectProperty] = useState(
    propertyData?.property_type
  );
  const [allPropertyType, setAllPropertyType] = useState(null);

  const [allAmenities, setAllAmenities] = useState(null);
  const [allHouseRules, setAllHouseRules] = useState(null);
  const [allCountries, setAllCountries] = useState(null);
  const [topAmenities, setTopAmenities] = useState(null);
  const [activeStep, setActiveStep] = useState(step);
  //step 2 state
  const [propertyName, setPropertyName] = useState(propertyData?.property_name);
  const [propertyDes, setPropertyDes] = useState(
    propertyData?.place_description
  );
  //step 3 state
  const [propertyCountry, setPropertyCountry] = useState({
    id: propertyData?.country_name?.id,
    name: propertyData?.country_name?.name,
  });
  const [postalCode, setPostalCode] = useState("");
  const [province, setProvince] = useState(propertyData?.address?.at(0)?.province || "");
  const [region, setRegion] = useState(propertyData?.address?.at(0)?.region || "");
  const [address, setAddress] = useState(propertyData?.address?.at(0)?.address);
  const [landmark, setLandmark] = useState("");
  const [city, setCity] = useState(propertyData?.address?.at(0)?.city);
  //step 4 state
  const [checkedItems, setCheckedItems] = useState([]);
  const [checkedTopAmenity, setCheckedTopAmenity] = useState([]);
  //step 5 state
  const [houseRules, setHouseRules] = useState([]);
  const [newRule, setNewRule] = useState("");
  const [rulesList, setRulesList] = useState([]);
  //step 6 state
  const [images, setImages] = useState([]);
  const [imagesPreview, setImagesPreview] = useState([]);
  const [imageErrors, setImageErrors] = useState([]);
  const [imageNames, setImageNames] = useState([]);
  //step 7 state
  const [hostImage, setHostImage] = useState(null);
  const [hostImagePreview, setHostImagePreview] = useState(null);
  const [dailyPrice, setDailyPrice] = useState(0);
  const [dailyWeekly, setDailyWeekly] = useState(0);
  const [offerBreakfast, setOfferBreakfast] = useState(
    propertyData?.offer_breakfast
  );
  const [isParking, setIsParking] = useState(propertyData?.parking);
  const [isStaffLanguage, setIsStaffLanguage] = useState(
    propertyData?.staff_language
  );
  const [hostName, setHostName] = useState(propertyData?.host?.host_name);
  const [hostLanguage, setHostLanguage] = useState(
    propertyData?.host?.Languages
  );
  const [rentWholeProperty, setRentWholeProperty] = useState(
    propertyData?.whole_property
  );
  const [rentRooms, setRentRooms] = useState(propertyData?.rent_rooms || false);
  const [numberOfPersons, setNumberOfPersons] = useState(propertyData?.whole_property_guests || 0);
  const [personPrices, setPersonPrices] = useState(propertyData?.whole_property_prices || []);

  //step 8 state
  // const [maxGuest, setMaxGuest] = useState(10);
  // const [eachRoomGuest, setEachRoomGuest] = useState(2);
  // const [rooms, setRooms] = useState(5);
  // const [beds, setBeds] = useState(5);
  const [bedroomName, setBedroomName] = useState("");
  const [bedroomDescription, setBedroomDescription] = useState("");
  const [bedroomPrice, setBedroomPrice] = useState(null);
  const [bedroomSize, setBedroomSize] = useState("");
  const [adult, setAdult] = useState(2);
  const [children, setChildren] = useState(0);
  // const [infants, setInfants] = useState(0);
  const [pets, setPets] = useState(0);
  const [selectedSubAmenities, setSelectedSubAmenities] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);
  const [singlebed, setSinglebed] = useState(0);
  const [doublebed, setDOublebed] = useState(0);
  const [largebed, setLargebed] = useState(0);
  const [extraLargebed, setextraLargeBed] = useState(0);
  const [bunkbed, setBunkbed] = useState(0);
  const [sofabed, setSofabed] = useState(0);
  const [futonMet, setFutonMet] = useState(0);
  const [bathrooms, setBathrooms] = useState(1);
  const [kitchen, setKitchen] = useState(0);
  const [cotValue, setCotValue] = useState("false");
  // const [allBeds, setAllBeds] = useState();
  // const [showBedTypes, setShowBedTypes] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [openRoomDialog, setOpenRoomDialog] = useState(false);
  const [openEditRoomDialog, setOpenEditRoomDialog] = useState(false);
  const [openAmenitiesDialog, setOpenAmeitiesDialog] = useState(false);
  const [openRoomImagesDialog, setOpenRoomImagesDialog] = useState(false);
  const [moreBeds, setMoreBeds] = useState(false);
  const [rooms, setRooms] = useState([]);
  const [roomIndex, setRoomIndex] = useState(null);
  const [roomPersonPrices, setRoomPersonPrices] = useState([]);
  // const lastElement = previewData?.property_images[previewData?.property_images.length -1]
  // const imageUrls = lastElement?.img2.split(',').map(url => url.trim()) || [];
  // const chunkArray = (arr, size) => {
  //   const result = [];
  //   for (let i = 0; i < arr.length; i += size) {
  //     result.push(arr.slice(i, i + size));
  //   }
  //   return result;
  // };

  // const rows = chunkArray(previewData?.property_images, 4);
  // console.log("chunked::",rows)
  //step 9 state
  const [addSurrounding, setAddSurrounding] = useState("");
  const [surroundingDistance, setSurroundingDistance] = useState("");
  const [surroundingList, setSurroundingList] = useState(
    propertyData?.property_surronding
  );
  const [distanceUnit, setDistanceUnit] = useState("");
  const [position, setPosition] = useState({
    lat: propertyData?.address?.at(0)?.lat,
    lng: propertyData?.address?.at(0)?.long,
  });
  const [dates, setDates] = useState([
    // "12-nov-2023",
    // "13-nov-2023",
    // "14-nov-2023",
    // "15-nov-2023",
  ]);

  const navigate = useNavigate();
  // const dispatch = useDispatch();
  //   const localizer = momentLocalizer(moment);
  const imageUploader = useRef(null);
  const hostImageUploader = useRef(null);
  // const {
  //   allPropertyType,
  //   allAmenities,
  //   allHouseRules,
  //   allCountries,
  //   topAmenities,
  //   previewData,
  // } = useSelector((state) => state?.isListProperty);
  const [mapLocation, setMapLocation] = useState(null);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);

  useEffect(() => {
    if (step === 0) {
      const fetchData = async () => {
        try {
          const res = await axios.get(
            `${process.env.REACT_APP_API}api/get-property-types?page=0&property_type=all`
          );
          setAllPropertyType(res?.data?.data);
        } catch (err) {
          console.log(err);
        }
      };
      fetchData();
    } else if (step === 2) {
      const fetchData = async () => {
        try {
          const res = await axios.get(
            `${process.env.REACT_APP_API}api/get-country/`
          );
          setAllCountries(res?.data);
        } catch (err) {
          console.log(err);
        }
      };
      fetchData();
    } else if (step === 3) {
      const fetchData = async () => {
        try {
          const res = await axios.get(
            `${process.env.REACT_APP_API}api/get-amenities`
          );
          setAllAmenities(res?.data?.data);
          const allSubAmenityIds = propertyData?.amenities?.flatMap((amenity) =>
            amenity?.subamenities?.map((subamenity) => subamenity?.id)
          );
          setCheckedItems(allSubAmenityIds || []);
        } catch (err) {
          console.log(err);
        }
      };
      const fetchTopData = async () => {
        try {
          const res = await axios.get(
            `${process.env.REACT_APP_API}api/get_topamenities`
          );
          setTopAmenities(res?.data?.data);
          // setCheckedTopAmenity(propertyData.top_amenities.map(item => item.id) || [])
          if (propertyData?.top_amenities) {
            const initialChecked = propertyData?.top_amenities?.map(
              (item) => item?.id
            );
            setCheckedTopAmenity(initialChecked || []);
          }
        } catch (err) {
          console.log(err);
        }
      };
      fetchData();
      fetchTopData();
    } else if (step === 4) {
      const fetchData = async () => {
        try {
          const res = await axios.get(
            `${process.env.REACT_APP_API}api/get-house-rules`
          );
          setAllHouseRules(res?.data?.data);
          setHouseRules(propertyData?.house_rule?.map((rule) => rule?.id) || []);
        } catch (err) {
          console.log(err);
        }
      };
      fetchData();
    }
  }, []);

  useEffect(() => {
    // Check if propertyData?.property_images is available
    if (propertyData?.property_images) {
      const initialImages = propertyData?.property_images?.map(
        (imgObj, index) => {
          const imgUrl = imgObj?.img;
          // Extract the image name after '/property_img/'
          const extractedName = imgUrl.split("/property_img/")[1];
          const image_ID = imgObj?.id
          return {
            file: imgUrl, // Use the img URL from propertyData
            name: extractedName, // Use the extracted name from the URL
            img_id:image_ID,
          };
        }
      );

      setImages(initialImages || []);
    }
  }, [propertyData]);

  useEffect(() => {
    // Update personPrices whenever numberOfPersons changes
    setPersonPrices((prevPrices) => {
      const newPrices = Array.from({ length: numberOfPersons }, (_, index) => {
        return prevPrices[index] || { person: index + 1, price: "" };
      });
      return newPrices;
    });
  }, [numberOfPersons]);

  const handleLocationChange = (newLocation) => {
    setMapLocation(newLocation);
  };

  // const handleCountry = (event) => {
  //   const selectedId = event.target.value;
  //   const selectedName = allCountries?.find(
  //     (option) => option.id === parseInt(selectedId)
  //   ).name;
  //   setPropertyCountry({ id: selectedId, name: selectedName });
  // };

  const handleDragEnd = (e) => {
    const lat = e.latLng.lat();
    const lng = e.latLng.lng();
    setPosition({ lat, lng });
    localStorage.setItem("lattitude", lat);
    localStorage.setItem("longitude", lng);
    handleLocationChange({ lat, lng });
  };

  const handleChangeTopAmenities = (event, id) => {
    if (event.target.checked) {
      setCheckedTopAmenity([...checkedTopAmenity, id]);
    } else {
      const updatedItems = checkedTopAmenity.filter((itemId) => itemId !== id);
      setCheckedTopAmenity(updatedItems);
    }
  };

  const handleChangeAmenities = (event, id) => {
    if (event.target.checked) {
      setCheckedItems([...checkedItems, id]);
    } else {
      const updatedItems = checkedItems.filter((itemId) => itemId !== id);
      setCheckedItems(updatedItems);
    }
  };

  const [checkin, setCheckin] = useState({
    from: propertyData?.check_in_time,
    until: propertyData?.check_in_time_until,
  });

  const [checkout, setCheckout] = useState({
    from: propertyData?.check_out_time,
    until: propertyData?.check_out_time_until,
  });

  const handleCheckin = (e) => {
    const { name, value } = e.target;
    setCheckin((prev) => ({
      ...prev,
      [name]: String(value),
    }));
  };

  const handleCheckout = (e) => {
    const { name, value } = e.target;
    setCheckout((prev) => ({
      ...prev,
      [name]: String(value),
    }));
  };

  // const handleImageChange = async (e) => {
  //   const files = Array.from(e.target.files);
  //   const updatedName = previewData?.property_name.replace(/ /g, '-');

  //   // Remove duplicates and limit to 30 images
  //   const newFiles = [];
  //   for (const file of files) {
  //     const fileExtension = file.name.split('.').pop();
  //     const baseName = file.name.replace(/\.[^/.]+$/, "");
  //     const newName = `${updatedName}-${images.length + newFiles.length + 1}.${fileExtension}`;

  //     if (!images.some((img) => img.name === newName)) {
  //       if (images.length + newFiles.length < MAX_IMAGE_COUNT) {
  //         if (file.size > MAX_IMAGE_SIZE) {
  //           try {
  //             const compressedBlob = await compressImage(file, MAX_IMAGE_SIZE);
  //             newFiles.push({ file: new File([compressedBlob], newName, { type: file.type }), name: newName });
  //           } catch (error) {
  //             console.error("Image compression failed:", error);
  //           }
  //         } else {
  //           newFiles.push({ file: new File([file], newName, { type: file.type }), name: newName });
  //         }
  //       } else {
  //         console.warn("Cannot add more than 30 images.");
  //       }
  //     }
  //   }

  //   setImages((prevImages) => [...prevImages, ...newFiles]);
  // };

  const handleChangeHouseRules = (event, id) => {
    if (event.target.checked) {
      setHouseRules([...houseRules, id]);
    } else {
      const updatedItems = houseRules.filter((itemId) => itemId !== id);
      setHouseRules(updatedItems);
    }
  };

  const handleRemoveImage = async (imageName, imageID) => {
    setImages((prevImages) =>
      prevImages.filter((image) => image.name !== imageName)
    );
    if(propertyData?.property_images?.length > 0){
      try {
        await axios.delete(
          `${process.env.REACT_APP_API}api/delete-image-list-of-property/${imageID}`
        );
      } catch (error) {
        console.error("Error deleting room:", error);
      }
    }
  };

  const handleHostImage = (e) => {
    setHostImage(e.target.files[0]);
    const file = e.target.files[0];
    const imageFile = URL.createObjectURL(file);
    setHostImagePreview(imageFile);
  };

  const compressImage = (file, maxSize) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.src = e.target.result;
        img.onload = () => {
          let { width, height } = img;
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");

          if (width > height) {
            if (width > 1000) {
              height *= 1000 / width;
              width = 1000;
            }
          } else {
            if (height > 1000) {
              width *= 1000 / height;
              height = 1000;
            }
          }

          canvas.width = width;
          canvas.height = height;
          ctx.drawImage(img, 0, 0, width, height);

          canvas.toBlob((blob) => {
            if (blob.size > maxSize) {
              reject(
                new Error("Image size exceeds the limit after compression.")
              );
            } else {
              resolve(blob);
            }
          }, file.type);
        };
      };
      reader.onerror = (err) => reject(err);
      reader.readAsDataURL(file);
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleCotValue = (event) => {
    setCotValue(event.target.value);
  };

  // const handleNext = () => {
  //   setActiveStep((prevActiveStep) => prevActiveStep + 1);
  // };

  const iconSize = xs ? 30 : 40;

  const handleForm1 = () => {
    if (activeStep === 0) {
      const formData = new FormData();
      formData.append("step", `${activeStep + 1}`);
      formData.append("property_type_id", selectProperty?.id);
      formData.append("destination_id", propertyData?.id);
      axios.post(`${process.env.REACT_APP_API}api/list-my-hotel`,formData, {
        headers: {
          authorization: 'Token ' + localStorage.getItem('hostToken'),
        }
      })
    }
     else if (activeStep === 1) {
      const formData = new FormData();
      formData.append("step", `${activeStep + 1}`);
      formData.append("destination_id", propertyData?.id);
      formData.append("property_name", propertyName);
      formData.append("place_description", propertyDes);
      // localStorage.setItem("property_name", propertyName);
      // localStorage.setItem("property_desc", propertyDes);
      // formData.append("Cancellation_policy", propertyDes);
      axios.post(`${process.env.REACT_APP_API}api/list-my-hotel`,formData, {
        headers: {
          authorization: 'Token ' + localStorage.getItem('hostToken'),
        }
      })
    }
     else if (activeStep === 2) {
      const formData = new FormData();
      formData.append("step", `${activeStep + 1}`);
      formData.append("destination_id", propertyData?.id);
      formData.append("country_id", propertyCountry?.id);
      formData.append("province", province);
      formData.append("region", region);
      formData.append("full_address", `${address} ${landmark ? `(${landmark})` : ""} ${postalCode ? `(${postalCode})` : ""}`);
      formData.append("city", city);
      formData.append("lat", position.lat);
      formData.append("long", position.lng);
      axios.post(`${process.env.REACT_APP_API}api/list-my-hotel`,formData, {
        headers: {
          authorization: 'Token ' + localStorage.getItem('hostToken'),
        }
      })
    }
     else if (activeStep === 3) {
      const formData = new FormData();
      formData.append("step", `${activeStep + 1}`);
      formData.append("destination_id", propertyData?.id);
      formData.append("subamenities", checkedItems);
      formData.append("topamenities", checkedTopAmenity)
      axios.post(`${process.env.REACT_APP_API}api/list-my-hotel`,formData, {
        headers: {
          authorization: 'Token ' + localStorage.getItem('hostToken'),
        }
      })
    } 
    else if (activeStep === 4) {
      const payloadData = {
        step: String(activeStep + 1),
        destination_id: propertyData?.id,
        house_rule: houseRules,
        check_in: String(checkin.from),
        check_in_until: String(checkin.until),
        check_out: String(checkout.from),
        check_out_until: String(checkin.until),
      };
      axios.post(`${process.env.REACT_APP_API}api/list-my-hotel`,payloadData, {
        headers: {
          "Content-Type": "application/json",
          authorization: 'Token ' + localStorage.getItem('hostToken'),
        }
      })
    }
     else if (activeStep === 5) {
      const formData = new FormData();
      formData.append("step", activeStep + 1);
      formData.append("destination_id", propertyData?.id);
      images.forEach((image) => {
        formData.append("images", image.file);
      });
      axios.post(`${process.env.REACT_APP_API}api/list-my-hotel`,formData, {
        headers: {
          authorization: 'Token ' + localStorage.getItem('hostToken'),
        }
      })
    }
     else if (activeStep === 6) {
      // const formData = new FormData();
      // formData.append("step", `${activeStep + 1}`);
      // formData.append("destination_id", propertyData?.id);
      // formData.append("price_daily", dailyPrice);
      // formData.append("price_weekly", dailyWeekly);
      // formData.append(
      //   "offer_breakfast",
      //   offerBreakfast === false ? "False" : "True"
      // );
      // formData.append(
      //   "parking_facility",
      //   isParking === false ? "False" : "True"
      // );
      // formData.append("staff_language", isStaffLanguage);
      // formData.append("host_details", hostName);
      // formData.append("host_language", hostLanguage);
      // formData.append("image", hostImagePreview);
      // formData.append("whole_property", rentWholeProperty === false ? "False" : "True");
      // localStorage.setItem("daily_price", dailyPrice);
      // localStorage.setItem("daily_weekly", dailyWeekly);
      const payloadData = {
        step: String(activeStep + 1),
        destination_id: propertyData?.id,
        price_daily: dailyPrice,
        price_weekly: dailyWeekly,
        offer_breakfast: offerBreakfast === false ? "False" : "True",
        parking_facility: isParking === false ? "False" : "True",
        staff_language: String(isStaffLanguage),
        host_details: String(hostName),
        host_language: String(hostLanguage),
        image: hostImage?.[0]?.file,
        whole_property: rentWholeProperty === false ? "False" : "True",
        whole_property_guests: numberOfPersons,
        whole_property_price: personPrices,
        rent_rooms: rentRooms === false ? "False" : "True",
      };
      axios.post(`${process.env.REACT_APP_API}api/list-my-hotel`, payloadData, {
        headers: {
          authorization: 'Token ' + localStorage.getItem('hostToken'),
          'Content-Type': 'application/json'  // Explicitly setting Content-Type
        }
      })
    } 
    // else if (activeStep === 7) {
    //   // const formData = new FormData();
    //   // formData.append("step", `${activeStep + 1}`);
    //   // formData.append("destination_id", localStorage.getItem("destinationId"));
    //   // formData.append("max_guest", maxGuest);
    //   // // formData.append("each_room_guest", eachRoomGuest);
    //   // formData.append("rooms", rooms);
    //   // formData.append("beds", beds);
    //   // formData.append("bathrooms", bathrooms);
    //   // formData.append("kitchen", kitchen);
    //   // localStorage.setItem(
    //   //   "all_rooms",
    //   //   singlebed +
    //   //     doublebed +
    //   //     largebed +
    //   //     extraLargebed +
    //   //     futonMet +
    //   //     bunkbed +
    //   //     sofabed
    //   // );
    //   // localStorage.setItem("max_guest", adult);
    //   // localStorage.setItem("bathrooms", bathrooms);
    //   // localStorage.setItem("kitchen", kitchen);
    //   const newRooms = rooms.filter((newRoom) => {
    //     return !previewData.room_details.some((existingRoom) =>
    //         existingRoom.rooms_name === newRoom.rooms_name &&
    //         existingRoom.description === newRoom.description &&
    //         existingRoom.price === newRoom.price &&
    //         JSON.stringify(existingRoom.bed_type) === JSON.stringify(newRoom.bed_type) &&
    //         existingRoom.room_size === newRoom.room_size &&
    //         existingRoom.adults === newRoom.adults &&
    //         existingRoom.infants === newRoom.infants &&
    //         existingRoom.pets === newRoom.pets &&
    //         JSON.stringify(existingRoom.amenitie_id) === JSON.stringify(newRoom.amenitie_id) &&
    //         JSON.stringify(existingRoom.images_id) === JSON.stringify(newRoom.images_id)
    //     );
    // });
    //   if (newRooms?.length > 0){
    //     const payloadData = {
    //       step: String(activeStep + 1),
    //       destination_id: localStorage.getItem("destinationId"),
    //       total_guests: String(4),
    //       bathrooms: String(bathrooms),
    //       kitchen: String(kitchen),
    //       cots: cotValue === false ? "False" : "True",
    //       rooms: newRooms,
    //     };
    //     dispatch(
    //       postForm({
    //         url: `${process.env.REACT_APP_API}api/list-my-hotel`,
    //         data: payloadData,
    //         contentType:"application/json"
    //       })
    //     );
    //   }
    // }
     else if (activeStep === 8) {
      const payload = {
        step: `${activeStep + 1}`,
        destination_id: propertyData?.id,
        list_of_property_surroding: surroundingList,
      };
      axios.post(`${process.env.REACT_APP_API}api/list-my-hotel`,payload, {
        headers: {
          authorization: 'Token ' + localStorage.getItem('hostToken'),
          "Content-Type": "application/json",
        }
      })
    }
    //  else if (activeStep === 9) {
    //   const formData = new FormData();
    //   formData.append("step", `${activeStep + 1}`);
    //   formData.append("destination_id", localStorage.getItem("destinationId"));
    //   formData.append("stay_range", `${range[0]}-${range[1]}`);
    //   dispatch(
    //     postForm({
    //       url: `${process.env.REACT_APP_API}api/list-my-hotel`,
    //       data: formData,
    //     })
    //   );
    // } 
    else if (activeStep === 10) {
          
         const data = {
            step: `${activeStep + 1}`,
            destination_id: propertyData?.id,
            room_available_date: {
              dates: dates,
            },
          }

          axios.post(`${process.env.REACT_APP_API}api/list-my-hotel`,data, {
            headers: {
              "Content-Type": "application/json",
              authorization: 'Token ' + localStorage.getItem('hostToken'),
            }
          })
    }
    // else if (activeStep === 11) {
    //   const formData = new FormData();
    //   formData.append("step", `${activeStep + 1}`);
    //   formData.append("destination_id", localStorage.getItem("destinationId"));
    //   formData.append("confirm", 1);
    //   dispatch(
    //     postForm({
    //       url: `${process.env.REACT_APP_API}api/list-my-hotel`,
    //       data: formData,
    //     })
    //   );
    // }
  };

  const deleteHostImage = () => {
    setHostImage(null);
    setHostImagePreview(null);
  };

  const handleButtonClick = (e) => {
    e.preventDefault();
    // handleNext();
    handleForm1();
    navigate("/edit", { state: { propertyId: propertyData?.id } });
    // if (activeStep === steps.length - 1) {
    //   navigate("/previewYourProperty");
    // }
  };

  const handleCountry = (event) => {
    const selectedId = event.target.value;
    const selectedName = allCountries?.find(
      (option) => option.id === parseInt(selectedId)
    ).name;
    setPropertyCountry({ id: selectedId, name: selectedName });
  };

  const handleBreakfastChange = (event) => {
    setOfferBreakfast(event.target.checked);
  };

  const handleParkingChange = (event) => {
    setIsParking(event.target.checked);
  };

  const handleRentProperty = (event) => {
    setRentWholeProperty(event.target.checked);
  };

  const Accordion = styled((props) => (
    <MuiAccordion disableGutters={true} elevation={0} square {...props} />
  ))(() => ({
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
  }));

  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
      expandIcon={<ExpandMoreIcon sx={{ fontSize: "1.3rem" }} />}
      {...props}
    />
  ))(({ theme }) => ({
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255, 255, 255, 0)"
        : "rgba(0, 0, 0, 0)",
    flexDirection: "row-reverse",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      transform: "rotate(180deg)",
    },
    "& .MuiAccordionSummary-content": {
      marginLeft: theme.spacing(1),
    },
  }));

  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
  }));

  const handleAccordionChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleCloseRoomDialog = () => {
    setOpenRoomDialog(!openRoomDialog);
    setMoreBeds(false);
    setBedroomName("");
    setBedroomPrice(null);
    setBedroomDescription("");
    setBedroomSize("");
    setAdult(2);
    setChildren(0);
    setPets(0);
    setSinglebed(0);
    setDOublebed(0);
    setLargebed(0);
    setextraLargeBed(0);
    setBunkbed(0);
    setSofabed(0);
    setFutonMet(0);
  };

  const handleSaveRoomData = () => {
    setOpenRoomDialog(!openRoomDialog);
    setMoreBeds(false);

    const beds = [];

    if (singlebed > 0) beds.push({ name: "Single Bed", number: singlebed });
    if (doublebed > 0) beds.push({ name: "Double Bed", number: doublebed });
    if (largebed > 0) beds.push({ name: "Large Bed", number: largebed });
    if (extraLargebed > 0)
      beds.push({ name: "Extra Large Bed", number: extraLargebed });
    if (bunkbed > 0) beds.push({ name: "Bunk Bed", number: bunkbed });
    if (sofabed > 0) beds.push({ name: "Sofa Bed", number: sofabed });
    if (futonMet > 0) beds.push({ name: "Futon Met", number: futonMet });

    const newRoom = {
      rooms_name: bedroomName,
      price: rentRooms ? roomPersonPrices : [],
      description: bedroomDescription,
      room_size: bedroomSize,
      adults: adult,
      infants: children,
      pets: pets,
      bed_type: beds,
      amenitie_id: selectedSubAmenities,
      images_id: selectedImages,
    };

    setRooms([...rooms, newRoom]);

    setBedroomName("");
    setRoomPersonPrices(
      Array.from({ length: 2 }, (_, index) => ({
        person: index + 1,
        price: "",
      }))
    );
    setBedroomDescription("");
    setBedroomSize("");
    setAdult(2);
    setChildren(0);
    setPets(0);
    setSinglebed(0);
    setDOublebed(0);
    setLargebed(0);
    setextraLargeBed(0);
    setBunkbed(0);
    setSofabed(0);
    setFutonMet(0);
    setSelectedSubAmenities([]);
    setSelectedImages([]);
  };

  function divideArrayIntoThreeParts(arr) {
    const totalLength = arr?.length;
    const partLength = Math?.floor(totalLength / 3);

    // Calculate the number of extra elements (those that can't be evenly divided)
    const remainder = totalLength % 3;

    // Define the three parts
    const part1 = arr?.slice(0, partLength + (remainder > 0 ? 1 : 0));
    const part2 = arr?.slice(
      part1?.length,
      part1?.length + partLength + (remainder > 1 ? 1 : 0)
    );
    const part3 = arr?.slice(part1?.length + part2?.length);

    return [part1, part2, part3];
  }

  const handleRoomAmenities = (event, id) => {
    if (event.target.checked) {
      setSelectedSubAmenities([...selectedSubAmenities, id]);
    } else {
      const updatedItems = selectedSubAmenities.filter(
        (itemId) => itemId !== id
      );
      setSelectedSubAmenities(updatedItems);
    }
  };

  const handleRoomImages = (url) => (event) => {
    if (event.target.checked) {
      setSelectedImages((prevSelected) => [...prevSelected, url]);
    } else {
      setSelectedImages((prevSelected) =>
        prevSelected.filter((item) => item !== url)
      );
    }
  };

  const handleCheckboxChange = (event, imgLink) => {
    if (event.target.checked) {
      setSelectedImages((prevState) => [...prevState, imgLink]);
    } else {
      setSelectedImages((prevState) =>
        prevState.filter((link) => link !== imgLink)
      );
    }
  };

  const [part1, part2, part3] = divideArrayIntoThreeParts(
    propertyData?.amenities
  );

  const handleEditRoom = (index) => {
    setRoomIndex(index);
    setBedroomName(rooms[index]?.rooms_name);
    setBedroomPrice(rooms[index]?.price);
    setBedroomDescription(rooms[index]?.description);
    setBedroomSize(rooms[index]?.room_size);
    setAdult(rooms[index]?.adults);
    setChildren(rooms[index]?.infants);
    setPets(rooms[index]?.pets);
    rooms[index]?.bed_type?.forEach((bedType) => {
      switch (bedType.name) {
        case "Single Bed":
          setSinglebed(bedType.number);
          break;
        case "Double Bed":
          setDOublebed(bedType.number);
          break;
        case "Large Bed":
          setLargebed(bedType.number);
          break;
        case "Extra Large Bed":
          setextraLargeBed(bedType.number);
          break;
        case "Bunk Bed":
          setBunkbed(bedType.number);
          break;
        case "Sofa Bed":
          setSofabed(bedType.number);
          break;
        case "Futon Met":
          setFutonMet(bedType.number);
          break;
        default:
          break;
      }
    });
    setOpenEditRoomDialog(!openEditRoomDialog);
    // const updatedRooms = rooms.filter((_, i) => i !== index);
    // setRooms(updatedRooms);
  };

  const deleteRoom = async (index) => {
    const updatedRooms = rooms.filter((_, i) => i !== index);
    setRooms(updatedRooms);
    try {
      await axios.delete(
        `${process.env.REACT_APP_API}api/room-update-delete/${propertyData?.room_details[index]?.id}`
      );
    } catch (error) {
      console.error("Error deleting room:", error);
    }
  };

  const handleCloseEditRoomDialog = () => {
    setOpenEditRoomDialog(!openEditRoomDialog);
    setMoreBeds(false);
    setBedroomName("");
    setBedroomPrice(null);
    setBedroomDescription("");
    setBedroomSize("");
    setAdult(2);
    setChildren(0);
    setPets(0);
    setSinglebed(0);
    setDOublebed(0);
    setLargebed(0);
    setextraLargeBed(0);
    setBunkbed(0);
    setSofabed(0);
    setFutonMet(0);
  };

  const handleSaveEditRoomData = async () => {
    setOpenEditRoomDialog(!openEditRoomDialog);
    setMoreBeds(false);

    const beds = [];

    if (singlebed > 0) beds.push({ name: "Single Bed", number: singlebed });
    if (doublebed > 0) beds.push({ name: "Double Bed", number: doublebed });
    if (largebed > 0) beds.push({ name: "Large Bed", number: largebed });
    if (extraLargebed > 0)
      beds.push({ name: "Extra Large Bed", number: extraLargebed });
    if (bunkbed > 0) beds.push({ name: "Bunk Bed", number: bunkbed });
    if (sofabed > 0) beds.push({ name: "Sofa Bed", number: sofabed });
    if (futonMet > 0) beds.push({ name: "Futon Met", number: futonMet });

    const updatedBeds = beds?.map((bed) => {
      // Try to find the corresponding bed in the existing room's bed_type array
      const existingBed = propertyData?.room_details[roomIndex]?.bed_type?.find(
        (existing) => existing?.name === bed?.name
      );

      return {
        id: existingBed ? existingBed.id : null, // Assign id if found
        name: bed.name,
        number: bed.number,
      };
    });

    const newRoom = {
      rooms_name: bedroomName,
      price: rentRooms ? roomPersonPrices : [],
      description: bedroomDescription,
      room_size: bedroomSize,
      adults: adult,
      infants: children,
      pets: pets,
      bed_type: updatedBeds,
      amenitie_id: selectedSubAmenities,
      images_id: selectedImages,
    };

    // setRooms([...rooms, newRoom]);
    setRooms((prevRooms) =>
      prevRooms?.map((room, i) => (i === roomIndex ? newRoom : room))
    );

    if (propertyData?.room_details?.length > 0) {
      try {
        const response = await axios.put(
          `${process.env.REACT_APP_API}api/room-update-delete/${propertyData?.room_details[roomIndex]?.id}`,
          newRoom
        );
      } catch (error) {
        console.error("Error updating room:", error);
      }
    }

    setBedroomName("");
    setRoomPersonPrices(
      Array.from({ length: 2 }, (_, index) => ({
        person: index + 1,
        price: "",
      }))
    );
    setBedroomDescription("");
    setBedroomSize("");
    setAdult(2);
    setChildren(0);
    setPets(0);
    setSinglebed(0);
    setDOublebed(0);
    setLargebed(0);
    setextraLargeBed(0);
    setBunkbed(0);
    setSofabed(0);
    setFutonMet(0);
  };

  const handleRoomPriceChange = (index, value) => {
    const updatedPrices = [...roomPersonPrices];
    updatedPrices[index] = {
      ...updatedPrices[index],
      price: value,
    };
    setRoomPersonPrices(updatedPrices);
  };

  const handleImageChange = async (e) => {
    const files = Array.from(e.target.files);
    const updatedName = propertyData?.property_name.replace(/ /g, "-");

    // Remove duplicates and limit to 30 images
    const newFiles = [];
    for (const file of files) {
      const fileExtension = file.name.split(".").pop();
      const baseName = file.name.replace(/\.[^/.]+$/, "");
      const newName = `${updatedName}-${
        images.length + newFiles.length + 1
      }.${fileExtension}`;

      if (!images.some((img) => img.name === newName)) {
        if (images.length + newFiles.length < MAX_IMAGE_COUNT) {
          if (file.size > MAX_IMAGE_SIZE) {
            try {
              const compressedBlob = await compressImage(file, MAX_IMAGE_SIZE);
              newFiles.push({
                file: new File([compressedBlob], newName, { type: file.type }),
                name: newName,
              });
            } catch (error) {
              console.error("Image compression failed:", error);
            }
          } else {
            newFiles.push({
              file: new File([file], newName, { type: file.type }),
              name: newName,
            });
          }
        } else {
          console.warn("Cannot add more than 30 images.");
        }
      }
    }

    setImages((prevImages) => [...prevImages, ...newFiles]);
  };

  const handleSurrounding = (e) => {
    setAddSurrounding(e.target.value);
  };

  const handleSurroundingDistance = (e) => {
    setSurroundingDistance(e.target.value);
  };

  const handleSurroundingList = () => {
    if (
      addSurrounding !== "" &&
      surroundingDistance !== "" &&
      distanceUnit !== ""
    ) {
      setSurroundingList([
        ...surroundingList,
        {
          Name: addSurrounding,
          Distance: surroundingDistance + " " + distanceUnit,
        },
      ]);
      setAddSurrounding("");
      setSurroundingDistance("");
    }
  };

  const handleDeleteSurrounding = async (event, indexToDelete, surroundingId) => {
    setSurroundingList((prevList) =>
      prevList.filter((_, index) => index !== indexToDelete)
    );
    try {
      await axios.delete(
        `${process.env.REACT_APP_API}api/delete-surrounding/${surroundingId}`
      );
    } catch (error) {
      console.error("Error deleting room:", error);
    }
  };

  const handleRangeChange = (event, newValue) => {
    setRange(newValue);
  };

  const [range, setRange] = useState([0, 37]);
  function valuetext(value) {
    return `${value} days`;
  }

  const formatDate = (inputDate) => {
    const date = new Date(inputDate);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" });
    const year = date.getFullYear();
    const formattedDate = `${day}-${month.toLowerCase()}-${year}`;
    return formattedDate;
  };

  const handleSelect = ({ start, end }) => {
    const now = new Date();

    // Check if the selected date is in the past
    if (start < now.setHours(0, 0, 0, 0)) {
      alert("You cannot select a past date.");
      return;
    }

    const title = window.prompt("Enter event title:");
    if (title) {
      const newEvent = {
        start,
        end,
        title,
      };
      setEvents([...events, newEvent]);
      setDates([...dates, formatDate(newEvent?.start)]);
    }
  };

  let combinedEvents = [...events, ...bookedEvents];

  const [deletedDates, setDeletedDates] = useState([])

  const handleDeleteEvent = (eventToDelete) => {
    const confirmDelete = window.confirm(
      `Do you want to delete the event: ${eventToDelete.title}?`
    );
  
    if (confirmDelete) {
      // Filter out the event to be deleted
      const updatedEvents = combinedEvents.filter(
        (event) => event.id !== eventToDelete.id
      );
  
      setEvents(updatedEvents);
  
      // Update the dates based on the remaining events
      const updatedDates = updatedEvents?.map(event => formatDate(event.start));
  
      // Ensure the dates are unique
      const uniqueDates = Array.from(new Set(updatedDates));
  
      setDates(uniqueDates);
      setDeletedDates((prevDeletedDates) => [
        ...prevDeletedDates,
        combinedEvents,
        formatDate(eventToDelete?.start),
      ]);

      deleteEvent([...deletedDates, formatDate(eventToDelete?.start)]);
    }
  };

  const deleteEvent = async (dates) => {
    
    const data = {
      destination_id: propertyData?.id,
      room_available_date: {
        dates: dates, // Assuming deletedDates is an array or variable you've defined
      },
    };
    try {
      await axios.post(
        `${process.env.REACT_APP_API}api/delete-room-availability`, 
        data,  // Payload (body)
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
    } catch (error) {
      console.error("Error deleting room:", error);
    }
  }

  const handleSelectSlot = (slotInfo) => {
    const now = new Date();
    if (slotInfo.start < now.setHours(0, 0, 0, 0)) {
        alert("You cannot select a past date.");
        return;
    }

    const allEvents = [...events, ...bookedEvents];

    const isEventOverlapping = allEvents.some(
        (event) =>
            slotInfo.start.getTime() === event.start.getTime() ||
            (slotInfo.start >= event.start && slotInfo.start < event.end)
    );

    if (isEventOverlapping) {
        alert("Selected slot overlaps with an existing event.");
        return;
    }

    const title = window.prompt("Enter event title:");
    if (title) {
        const newEvent = {
            title,
            start: slotInfo.start,
            end: slotInfo.end,
            id: new Date().getTime(), // Generate unique ID
        };
        setEvents([...events, newEvent]);
        setDates([...dates, formatDate(newEvent?.start)]);
    }
};

  const eventStyleGetter = (event) => {
    const style = {
      backgroundColor: event.title === "Booked" ? "#ffcccb" : "#3174ad", // Red for booked dates, blue for others
      color: "white",
      borderRadius: "5px",
      padding: "5px",
    };
    return {
      style,
    };
  };

  const handlePriceChange = (index, value) => {
    const updatedPrices = [...personPrices];
    updatedPrices[index] = {
      ...updatedPrices[index],
      price: value,
    };
    setPersonPrices(updatedPrices);
  };

  const handleRentRooms = (event) => {
    setRentRooms(event.target.checked);
  };

  const stepsContent = [
    // Step 1 Content
    <Box sx={{ mt: "1rem" }}>
      <Typography
        variant="h6"
        sx={{ fontSize: "1.25rem", color: "black.main" }}
      >
        <Typography
          variant="caption"
          fontWeight={600}
          sx={{ color: "#E87B25", fontSize: "1.25rem" }}
        >
          Step 1 :
        </Typography>
        Your Property Type
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row", md: "row" },
          width: "100%",
          mt: "1rem",
        }}
      >
        <Grid item xs={12} container spacing={{ xs: "15%", sm: 8, md: 8 }}>
          {allPropertyType?.map((ele, i) => (
            <Grid key={i} item md={3}>
              <Box
                onClick={() => setSelectProperty(ele)}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  height: "6.0625rem",
                  justifyContent: "space-between",
                  borderRadius: "0.9375rem",
                  border:
                    selectProperty?.id === ele?.id
                      ? "2px solid #232674"
                      : "1px solid #E7E7E7",
                  p: "1rem",
                  cursor: "pointer",
                }}
              >
                <Box sx={{ height: "2rem", width: "2rem" }}>
                  <img
                    src={ele?.logo}
                    alt="No Preview"
                    style={{
                      height: "100%",
                      width: "100%",
                      objectFit: "cover",
                    }}
                    onError={(e) => {
                      e.onerror = null;
                      e.target.src = require("../../Assets/Images/no-preview.jpeg");
                    }}
                  />
                </Box>
                <Typography>{ele?.name}</Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>,

    // Step 2 Content
    <Box sx={{ mt: "1rem" }}>
      <Typography
        variant="h6"
        sx={{ fontSize: "1.25rem", color: "black.main" }}
      >
        <Typography
          variant="caption"
          fontWeight={600}
          sx={{ color: "#E87B25", fontSize: "1.25rem" }}
        >
          Step 2 :
        </Typography>
        What would you like to call your place?
      </Typography>
      <Box>
        <Typography
          variant="h6"
          fontWeight={500}
          sx={{
            fontSize: "1rem",
            color: "black.main",
            mt: "1rem",
            mb: ".5rem",
          }}
        >
          Name of your property
        </Typography>
        <TextField
          label="Property name"
          variant="outlined"
          autoComplete="false"
          type="text"
          name="email"
          required
          value={propertyName}
          onChange={(e) =>
            setPropertyName(e.target.value)
          }
          sx={{
            width: { xs: "100%", sm: "40%", md: "40%" },
            "& .MuiInputBase-root": {
              height: "3.5rem",
              borderRadius: ".625rem",
            },
          }}
        />
        <Typography
          variant="h6"
          fontWeight={500}
          sx={{
            fontSize: "1rem",
            color: "black.main",
            mt: "1rem",
            mb: ".5rem",
          }}
        >
          Tell us a little about your place
        </Typography>
        <TextField
          id="outlined-basic"
          label="Enter description..."
          variant="outlined"
          // fullWidth={true}
          required
          value={propertyDes}
          onChange={(e) =>
            setPropertyDes(e.target.value)
          }
          sx={{
            width: { xs: "100%", sm: "50%", md: "50%" },
            "& .css-19d3wcu-MuiInputBase-root-MuiOutlinedInput-root": {
              borderRadius: ".625rem",
            },
          }}
          multiline
          minRows={5}
        />
      </Box>
    </Box>,

    // step 3
    <Box
      sx={{ mt: "1rem", marginBottom: { xs: "2rem", sm: "6rem", md: "6rem" } }}
    >
      <Typography
        variant="h6"
        sx={{ fontSize: "1.25rem", color: "black.main" }}
      >
        <Typography
          variant="caption"
          fontWeight={600}
          sx={{ color: "#E87B25", fontSize: "1.25rem" }}
        >
          Step 3 :
        </Typography>
        Tell us where you are located
      </Typography>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          mt: "1rem",
          gap: { xs: "1rem", sm: "5rem", md: "5rem" },
        }}
      >
        <Box sx={{ width: "50%" }}>
          <Typography
            variant="h6"
            fontWeight={500}
            sx={{ fontSize: "1rem", mb: ".5rem" }}
          >
            Country
          </Typography>

          <FormControl sx={{ width: "100%" }}>
            <Select
              sx={{ borderRadius: ".625rem" }}
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={propertyCountry.id}
              // label="Age"
              onChange={(e) => handleCountry(e)}
            >
              {allCountries?.map((code, index) => (
                <MenuItem key={index} value={code.id}>
                  {code?.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        <Box sx={{ width: "50%" }}>
          <Typography
            variant="h6"
            fontWeight={500}
            sx={{ fontSize: "1rem", mb: ".5rem" }}
          >
            Postal code
          </Typography>
          <TextField
            placeholder="e.g. SWp123"
            // label="Full Name"
            variant="outlined"
            autoComplete="false"
            type="email"
            name="email"
            required
            value={postalCode}
            onChange={(e) =>
              setPostalCode(e.target.value.replace(/[^0-9]/gi, ""))
            }
            sx={{
              width: "100%",
              "& .MuiInputBase-root": {
                height: "3.5rem",
                borderRadius: ".625rem",
              },
            }}
          />
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          mt: "1rem",
          gap: { xs: "1rem", sm: "5rem", md: "5rem" },
        }}
      >
        <Box sx={{ width: "50%" }}>
          <Typography
            variant="h6"
            fontWeight={500}
            sx={{ fontSize: "1rem", mb: ".5rem" }}
          >
            Province
          </Typography>
          <TextField
            // placeholder="e.g. SWp123"
            // label="Full Name"
            variant="outlined"
            autoComplete="false"
            type="text"
            name="email"
            required
            value={province}
            onChange={(e) => setProvince(e.target.value)}
            sx={{
              width: "100%",
              "& .MuiInputBase-root": {
                height: "3.5rem",
                borderRadius: ".625rem",
              },
            }}
          />
        </Box>

        <Box sx={{ width: "50%" }}>
          <Typography
            variant="h6"
            fontWeight={500}
            sx={{ fontSize: "1rem", mb: ".5rem" }}
          >
            Region
          </Typography>
          <TextField
            // placeholder="e.g. SWp123"
            // label="Full Name"
            variant="outlined"
            autoComplete="false"
            type="text"
            name="email"
            required
            value={region}
            onChange={(e) => setRegion(e.target.value)}
            sx={{
              width: "100%",
              "& .MuiInputBase-root": {
                height: "3.5rem",
                borderRadius: ".625rem",
              },
            }}
          />
        </Box>
      </Box>

      <Typography
        variant="h6"
        fontWeight={500}
        sx={{
          fontSize: "1rem",
          color: "black.main",
          mt: "1rem",
          mb: ".5rem",
        }}
      >
        Street address
      </Typography>
      <TextField
        placeholder="e.g. 22 baker street"
        id="outlined-basic"
        // label="Enter description..."
        variant="outlined"
        // fullWidth={true}
        required
        type="text"
        value={address}
        onChange={(e) => setAddress(e.target.value)}
        sx={{
          width: "100%",
          "& .css-19d3wcu-MuiInputBase-root-MuiOutlinedInput-root": {
            borderRadius: ".625rem",
          },
        }}
        multiline
        minRows={1.5}
        maxRows={1.5}
      />

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          mt: "1rem",
          gap: { xs: "1rem", sm: "5rem", md: "5rem" },
        }}
      >
        <Box sx={{ width: "50%" }}>
          <Typography
            variant="h6"
            fontWeight={500}
            sx={{ fontSize: "1rem", mb: ".5rem" }}
          >
            Landmark (optional)
          </Typography>

          <TextField
            placeholder="e.g. near plaza hotel"
            // label="Full Name"
            variant="outlined"
            autoComplete="false"
            type="text"
            name="email"
            required
            value={landmark}
            onChange={(e) =>
              setLandmark(e.target.value.replace(/[^a-z ]/gi, ""))
            }
            sx={{
              width: "100%",
              "& .MuiInputBase-root": {
                height: "3.5rem",
                borderRadius: ".625rem",
              },
            }}
          />
        </Box>

        <Box
          sx={{ width: "50%", marginTop: { xs: "1.5rem", sm: "0", md: "0" } }}
        >
          <Typography
            variant="h6"
            fontWeight={500}
            sx={{ fontSize: "1rem", mb: ".5rem" }}
          >
            Town/city
          </Typography>
          <TextField
            placeholder="e.g. London"
            // label="Full Name"
            variant="outlined"
            autoComplete="false"
            type="text"
            name="email"
            required
            value={city}
            onChange={(e) => setCity(e.target.value.replace(/[^a-z ]/gi, ""))}
            sx={{
              width: "100%",
              "& .MuiInputBase-root": {
                height: "3.5rem",
                borderRadius: ".625rem",
              },
            }}
          />
        </Box>
      </Box>

      {/* Map */}

      <Box
        sx={{
          marginTop: "2rem",
        }}
      >
        <LoadScript googleMapsApiKey="AIzaSyCY7dC83W8OoPR8Xe0vMdxo_BDVfq1q89Q">
          <GoogleMap
            mapContainerStyle={{ width: "100%", height: "350px" }}
            zoom={8}
            center={position}
          >
            <Marker
              position={position}
              draggable={true}
              onDragEnd={handleDragEnd}
            />
          </GoogleMap>
        </LoadScript>
        {mapLocation && (
          <div>
            <h2>Selected Location:</h2>
            <p>Latitude: {mapLocation.lat}</p>
            <p>Longitude: {mapLocation.lng}</p>
          </div>
        )}
      </Box>
    </Box>,

    // step 4
    <Box
      sx={{ mt: "1rem", marginBottom: { xs: "2rem", sm: "6rem", md: "6rem" } }}
    >
      <Typography
        variant="h6"
        sx={{ fontSize: "1.25rem", color: "black.main" }}
      >
        <Typography
          variant="caption"
          fontWeight={600}
          sx={{ color: "#E87B25", fontSize: "1.25rem" }}
        >
          Step 4 :
        </Typography>
        What amenities are available?
      </Typography>

      <Box sx={{ mt: "1rem" }}>
        <Typography
          variant="h6"
          fontWeight={500}
          sx={{ fontSize: "1rem", mb: ".5rem" }}
        >
          Top Amenities
        </Typography>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column", width: "33%" }}>
            {topAmenities &&
              topAmenities?.slice(0, 4)?.map((item, index) => (
                <Box
                  key={{ index }}
                  sx={{
                    mb: "1rem",
                  }}
                >
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checkedTopAmenity.includes(item?.id)}
                          onChange={(event) =>
                            handleChangeTopAmenities(event, item?.id)
                          }
                        />
                      }
                      label={
                        <Typography
                          sx={{
                            fontSize: "14px", // Adjust the padding of the label
                          }}
                        >
                          {item?.name}
                        </Typography>
                      }
                    />
                  </FormGroup>
                </Box>
              ))}
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", width: "33%" }}>
            {topAmenities &&
              topAmenities?.slice(4, 7)?.map((item, index) => (
                <Box
                  key={{ index }}
                  sx={{
                    mb: "1rem",
                  }}
                >
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checkedTopAmenity.includes(item?.id)}
                          onChange={(event) =>
                            handleChangeTopAmenities(event, item?.id)
                          }
                        />
                      }
                      label={
                        <Typography
                          sx={{
                            fontSize: "14px", // Adjust the padding of the label
                          }}
                        >
                          {item?.name}
                        </Typography>
                      }
                    />
                  </FormGroup>
                </Box>
              ))}
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", width: "33%" }}>
            {topAmenities &&
              topAmenities?.slice(7)?.map((item, index) => (
                <Box
                  key={{ index }}
                  sx={{
                    mb: "1rem",
                  }}
                >
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checkedTopAmenity.includes(item?.id)}
                          onChange={(event) =>
                            handleChangeTopAmenities(event, item?.id)
                          }
                        />
                      }
                      label={
                        <Typography
                          sx={{
                            fontSize: "14px", // Adjust the padding of the label
                          }}
                        >
                          {item?.name}
                        </Typography>
                      }
                    />
                  </FormGroup>
                </Box>
              ))}
          </Box>
        </Box>
      </Box>

      <Box sx={{ mt: "1rem" }}>
        <Typography
          variant="h6"
          fontWeight={500}
          sx={{ fontSize: "1rem", mb: ".5rem" }}
        >
          Amenities
        </Typography>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column", width: "33%" }}>
            {allAmenities &&
              allAmenities?.amenities?.slice(0, 9)?.map((ele, i) => (
                <Box
                  key={i}
                  sx={{
                    mb: "1rem",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "bold",
                    }}
                  >
                    {ele?.name}
                  </Typography>
                  {ele?.subamenities && ele?.subamenities?.length > 0 && (
                    <Box>
                      {ele?.subamenities?.map((subAmenity, subIndex) => (
                        <FormGroup key={subIndex}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={checkedItems.includes(subAmenity?.id)}
                                onChange={(event) =>
                                  handleChangeAmenities(event, subAmenity?.id)
                                }
                              />
                            }
                            label={
                              <Typography
                                sx={{
                                  fontSize: "14px", // Adjust the padding of the label
                                }}
                              >
                                {subAmenity?.name}
                              </Typography>
                            }
                          />
                        </FormGroup>
                      ))}
                    </Box>
                  )}
                </Box>
              ))}
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", width: "36%" }}>
            {allAmenities &&
              allAmenities?.amenities?.slice(9, 18)?.map((ele, i) => (
                <Box
                  key={i}
                  sx={{
                    mb: "1rem",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "bold",
                    }}
                  >
                    {ele?.name}
                  </Typography>
                  {ele?.subamenities && ele?.subamenities?.length > 0 && (
                    <Box>
                      {ele?.subamenities?.map((subAmenity, subIndex) => (
                        <FormControlLabel
                          key={subIndex}
                          control={
                            <Checkbox
                              checked={checkedItems.includes(subAmenity?.id)}
                              value={subAmenity?.name}
                              onChange={(event) =>
                                handleChangeAmenities(event, subAmenity?.id)
                              }
                            />
                          }
                          label={subAmenity?.name}
                        />
                      ))}
                    </Box>
                  )}
                </Box>
              ))}
          </Box>

          <Box sx={{ display: "flex", flexDirection: "column", width: "23%" }}>
            {allAmenities &&
              allAmenities?.amenities?.slice(18)?.map((ele, i) => (
                <Box
                  key={i}
                  sx={{
                    mb: "1rem",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "bold",
                    }}
                  >
                    {ele?.name}
                  </Typography>
                  {ele?.subamenities && ele?.subamenities?.length > 0 && (
                    <Box>
                      {ele?.subamenities?.map((subAmenity, subIndex) => (
                        <FormControlLabel
                          key={subIndex}
                          control={
                            <Checkbox
                              checked={checkedItems.includes(subAmenity?.id)}
                              value={subAmenity?.name}
                              onChange={(event) =>
                                handleChangeAmenities(event, subAmenity?.id)
                              }
                            />
                          }
                          label={subAmenity?.name}
                        />
                      ))}
                    </Box>
                  )}
                </Box>
              ))}
          </Box>
        </Box>
      </Box>
    </Box>,

    // step 5
    <Box sx={{ mt: "1rem" }}>
      <Typography
        variant="h6"
        sx={{ fontSize: "1.25rem", color: "black.main" }}
      >
        <Typography
          variant="caption"
          fontWeight={600}
          sx={{ color: "#E87B25", fontSize: "1.25rem" }}
        >
          Step 5 :&nbsp;
        </Typography>
        Layout some house rules
      </Typography>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: { xs: "column", sm: "row", md: "row" },
          marginBottom: "5rem",
        }}
      >
        <Box sx={{ mt: "1rem", width: { xs: "100%", sm: "49%", md: "49%" } }}>
          <Typography
            variant="h6"
            fontWeight={500}
            sx={{ fontSize: "1rem", mb: ".5rem" }}
          >
            House Rules
          </Typography>

          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "1.5rem",
            }}
          >
            <FormControl sx={{ width: "45%" }}>
              <InputLabel
                id="demo-simple-select-label"
                sx={{ lineHeight: ".7rem" }}
              >
                Check in
              </InputLabel>
              <Select
                label="Check in"
                id="demo-simple-select"
                onChange={handleCheckin}
                name="from"
                value={checkin.from}
                sx={{ height: "40px" }}
              >
                <MenuItem value={"00:00:00"}>00:00</MenuItem>
                <MenuItem value={"01:00:00"}>01:00</MenuItem>
                <MenuItem value={"02:00:00"}>02:00</MenuItem>
                <MenuItem value={"03:00:00"}>03:00</MenuItem>
                <MenuItem value={"04:00:00"}>04:00</MenuItem>
                <MenuItem value={"05:00:00"}>05:00</MenuItem>
                <MenuItem value={"06:00:00"}>06:00</MenuItem>
                <MenuItem value={"07:00:00"}>07:00</MenuItem>
                <MenuItem value={"08:00:00"}>08:00</MenuItem>
                <MenuItem value={"09:00:00"}>09:00</MenuItem>
                <MenuItem value={"10:00:00"}>10:00</MenuItem>
                <MenuItem value={"11:00:00"}>11:00</MenuItem>
                <MenuItem value={"12:00:00"}>12:00</MenuItem>
                <MenuItem value={"13:00:00"}>13:00</MenuItem>
                <MenuItem value={"14:00:00"}>14:00</MenuItem>
                <MenuItem value={"15:00:00"}>15:00</MenuItem>
                <MenuItem value={"16:00:00"}>16:00</MenuItem>
                <MenuItem value={"17:00:00"}>17:00</MenuItem>
                <MenuItem value={"18:00:00"}>18:00</MenuItem>
                <MenuItem value={"19:00:00"}>19:00</MenuItem>
                <MenuItem value={"20:00:00"}>20:00</MenuItem>
                <MenuItem value={"21:00:00"}>21:00</MenuItem>
                <MenuItem value={"22:00:00"}>22:00</MenuItem>
                <MenuItem value={"23:00:00"}>23:00</MenuItem>
                <MenuItem value={"24:00:00"}>24:00</MenuItem>
              </Select>
            </FormControl>
            <FormControl sx={{ width: "45%" }}>
              <InputLabel
                id="demo-simple-select-label"
                sx={{ lineHeight: ".7rem" }}
              >
                Until
              </InputLabel>
              <Select
                label="Until"
                id="demo-simple-select"
                onChange={handleCheckin}
                value={checkin.until}
                name="until"
                sx={{ height: "40px" }}
              >
                <MenuItem value={"00:00:00"}>00:00</MenuItem>
                <MenuItem value={"01:00:00"}>01:00</MenuItem>
                <MenuItem value={"02:00:00"}>02:00</MenuItem>
                <MenuItem value={"03:00:00"}>03:00</MenuItem>
                <MenuItem value={"04:00:00"}>04:00</MenuItem>
                <MenuItem value={"05:00:00"}>05:00</MenuItem>
                <MenuItem value={"06:00:00"}>06:00</MenuItem>
                <MenuItem value={"07:00:00"}>07:00</MenuItem>
                <MenuItem value={"08:00:00"}>08:00</MenuItem>
                <MenuItem value={"09:00:00"}>09:00</MenuItem>
                <MenuItem value={"10:00:00"}>10:00</MenuItem>
                <MenuItem value={"11:00:00"}>11:00</MenuItem>
                <MenuItem value={"12:00:00"}>12:00</MenuItem>
                <MenuItem value={"13:00:00"}>13:00</MenuItem>
                <MenuItem value={"14:00:00"}>14:00</MenuItem>
                <MenuItem value={"15:00:00"}>15:00</MenuItem>
                <MenuItem value={"16:00:00"}>16:00</MenuItem>
                <MenuItem value={"17:00:00"}>17:00</MenuItem>
                <MenuItem value={"18:00:00"}>18:00</MenuItem>
                <MenuItem value={"19:00:00"}>19:00</MenuItem>
                <MenuItem value={"20:00:00"}>20:00</MenuItem>
                <MenuItem value={"21:00:00"}>21:00</MenuItem>
                <MenuItem value={"22:00:00"}>22:00</MenuItem>
                <MenuItem value={"23:00:00"}>23:00</MenuItem>
                <MenuItem value={"24:00:00"}>24:00</MenuItem>
              </Select>
            </FormControl>
          </Box>

          <Box
            sx={{
              width: "100",
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "1.5rem",
            }}
          >
            <FormControl sx={{ width: "45%" }}>
              <InputLabel
                id="demo-simple-select-label"
                sx={{ lineHeight: ".7rem" }}
              >
                Check out
              </InputLabel>
              <Select
                label="Check out"
                id="demo-simple-select"
                onChange={handleCheckout}
                name="from"
                value={checkout.from}
                sx={{ height: "40px" }}
              >
                <MenuItem value={"00:00:00"}>00:00</MenuItem>
                <MenuItem value={"01:00:00"}>01:00</MenuItem>
                <MenuItem value={"02:00:00"}>02:00</MenuItem>
                <MenuItem value={"03:00:00"}>03:00</MenuItem>
                <MenuItem value={"04:00:00"}>04:00</MenuItem>
                <MenuItem value={"05:00:00"}>05:00</MenuItem>
                <MenuItem value={"06:00:00"}>06:00</MenuItem>
                <MenuItem value={"07:00:00"}>07:00</MenuItem>
                <MenuItem value={"08:00:00"}>08:00</MenuItem>
                <MenuItem value={"09:00:00"}>09:00</MenuItem>
                <MenuItem value={"10:00:00"}>10:00</MenuItem>
                <MenuItem value={"11:00:00"}>11:00</MenuItem>
                <MenuItem value={"12:00:00"}>12:00</MenuItem>
                <MenuItem value={"13:00:00"}>13:00</MenuItem>
                <MenuItem value={"14:00:00"}>14:00</MenuItem>
                <MenuItem value={"15:00:00"}>15:00</MenuItem>
                <MenuItem value={"16:00:00"}>16:00</MenuItem>
                <MenuItem value={"17:00:00"}>17:00</MenuItem>
                <MenuItem value={"18:00:00"}>18:00</MenuItem>
                <MenuItem value={"19:00:00"}>19:00</MenuItem>
                <MenuItem value={"20:00:00"}>20:00</MenuItem>
                <MenuItem value={"21:00:00"}>21:00</MenuItem>
                <MenuItem value={"22:00:00"}>22:00</MenuItem>
                <MenuItem value={"23:00:00"}>23:00</MenuItem>
                <MenuItem value={"24:00:00"}>24:00</MenuItem>
              </Select>
            </FormControl>
            <FormControl sx={{ width: "45%" }}>
              <InputLabel
                id="demo-simple-select-label"
                sx={{ lineHeight: ".7rem" }}
              >
                Until
              </InputLabel>
              <Select
                label="Until"
                id="demo-simple-select"
                onChange={handleCheckout}
                name="until"
                value={checkout.until}
                sx={{ height: "40px" }}
              >
                <MenuItem value={"00:00:00"}>00:00</MenuItem>
                <MenuItem value={"01:00:00"}>01:00</MenuItem>
                <MenuItem value={"02:00:00"}>02:00</MenuItem>
                <MenuItem value={"03:00:00"}>03:00</MenuItem>
                <MenuItem value={"04:00:00"}>04:00</MenuItem>
                <MenuItem value={"05:00:00"}>05:00</MenuItem>
                <MenuItem value={"06:00:00"}>06:00</MenuItem>
                <MenuItem value={"07:00:00"}>07:00</MenuItem>
                <MenuItem value={"08:00:00"}>08:00</MenuItem>
                <MenuItem value={"09:00:00"}>09:00</MenuItem>
                <MenuItem value={"10:00:00"}>10:00</MenuItem>
                <MenuItem value={"11:00:00"}>11:00</MenuItem>
                <MenuItem value={"12:00:00"}>12:00</MenuItem>
                <MenuItem value={"13:00:00"}>13:00</MenuItem>
                <MenuItem value={"14:00:00"}>14:00</MenuItem>
                <MenuItem value={"15:00:00"}>15:00</MenuItem>
                <MenuItem value={"16:00:00"}>16:00</MenuItem>
                <MenuItem value={"17:00:00"}>17:00</MenuItem>
                <MenuItem value={"18:00:00"}>18:00</MenuItem>
                <MenuItem value={"19:00:00"}>19:00</MenuItem>
                <MenuItem value={"20:00:00"}>20:00</MenuItem>
                <MenuItem value={"21:00:00"}>21:00</MenuItem>
                <MenuItem value={"22:00:00"}>22:00</MenuItem>
                <MenuItem value={"23:00:00"}>23:00</MenuItem>
                <MenuItem value={"24:00:00"}>24:00</MenuItem>
              </Select>
            </FormControl>
          </Box>

          <Box>
            <Box
              sx={{
                width: { xs: "100%", sm: "100%", md: "100%" },
              }}
            >
              {allHouseRules?.house_rules?.toReversed()?.map((ele, i) => (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    // mb: ".5rem",
                    alignItems: "center",
                    // border: "1px solid red",
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <BedOutlinedIcon sx={{ fontSize: "1.3rem", mr: ".5rem" }} />
                    <Typography
                      variant="body2"
                      fontWeight={400}
                      sx={{ fontSize: "1rem" }}
                    >
                      {ele?.rule}
                    </Typography>
                  </Box>

                  <FormControlLabel
                    sx={{ pr: "0rem", mr: "-1rem" }}
                    control={
                      <Switch
                        checked={houseRules.includes(ele?.id)}
                        onChange={(event) =>
                          handleChangeHouseRules(event, ele?.id)
                        }
                      />
                    }
                  />
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>,

    // step 6
    <Box sx={{ mt: "1rem" }}>
      <Typography
        variant="h6"
        sx={{ fontSize: "1.25rem", color: "black.main" }}
      >
        <Typography
          variant="caption"
          fontWeight={600}
          sx={{ color: "#E87B25", fontSize: "1.25rem" }}
        >
          Step 6 :
        </Typography>
        Add some pictures
      </Typography>

      <Box
        sx={{
          width: "100%",
          mt: "1rem",
          mb: "6rem",
        }}
      >
        <input
          type="file"
          ref={imageUploader}
          multiple={true}
          onChange={handleImageChange}
          accept="image/png, image/gif, image/jpeg"
          // accept="image/*"
          style={{ visibility: "hidden" }}
        />
        <Box
          display="flex"
          justifyContent="center"
          sx={{
            zIndex: 2,
            mb: "1rem",
            // top: { xs: "90%", sm: "50%", md: "50%" },
            // left: { xs: "50%", sm: "50%", md: "50%" },
            // transform: "translate(-50%, -50%)",
          }}
        >
          <Button
            onClick={() => imageUploader.current.click()}
            variant="contained"
            // startIcon={<CreateIcon />}
            sx={{ textTransform: "capitalize" }}
          >
            Upload Pictures
          </Button>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            gap: 2,
            filter: "blur(0.2px)",
          }}
        >
          {images &&
            images?.map((ele, i) => (
              <Box
                key={i}
                sx={{
                  width: "8rem",
                  height: "8rem",
                  position: "relative",
                }}
              >
                <img
                  src={
                    typeof ele.file === "string"
                      ? `https://server.voyagerstales.com${ele.file}`
                      : URL.createObjectURL(ele.file)
                  }
                  style={{ width: "100%", height: "100%", objectFit: "fill" }}
                />
                <button
                  onClick={() => handleRemoveImage(ele.name, ele?.img_id)}
                  style={{
                    height: "20px",
                    width: "20px",
                    position: "absolute",
                    top: "2px",
                    right: "2px",
                    backgroundColor: "gray",
                    color: "black",
                    border: "none",
                    borderRadius: "50%",
                    cursor: "pointer",
                    fontWeight: "bold",
                  }}
                >
                  X
                </button>
              </Box>
            ))}
        </Box>
      </Box>
    </Box>,

    // step 7
    <Box sx={{ mt: "1rem", marginBottom: "5rem" }}>
      <Typography
        variant="h6"
        sx={{ fontSize: "1.25rem", color: "black.main" }}
      >
        <Typography
          variant="caption"
          fontWeight={600}
          sx={{ color: "#E87B25", fontSize: "1.25rem" }}
        >
          Step 7 :
        </Typography>
        Add your Host details
      </Typography>

      <Box
        sx={{ mt: "1rem", display: "flex", justifyContent: "space-between" }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            width: "50%",
          }}
        >
          <Typography variant="h6">Host Details</Typography>
          <Box
            sx={{
              marginTop: ".5rem",
            }}
          >
            <TextField
              value={hostName}
              onChange={(e) => setHostName(e.target.value)}
              label="Host Name"
            />
          </Box>
          <Box
            sx={{
              marginTop: "2rem",
            }}
          >
            <TextField
              value={hostLanguage}
              onChange={(e) => setHostLanguage(e.target.value)}
              label="Languages You know"
            />
          </Box>
          <Box
            sx={{
              width: "100%",
              mt: "1rem",
            }}
          >
            <input
              type="file"
              ref={hostImageUploader}
              onChange={handleHostImage}
              accept="image/png, image/gif, image/jpeg"
              style={{ visibility: "hidden" }}
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box>
                <Button
                  onClick={() => hostImageUploader.current.click()}
                  variant="contained"
                  // startIcon={<CreateIcon />}
                  sx={{ textTransform: "capitalize" }}
                >
                  Upload Pictures
                </Button>
              </Box>
              <Box sx={{}}>
                {hostImagePreview && (
                  <Box
                    sx={{
                      position: "relative",
                    }}
                  >
                    <Box sx={{ width: "8rem", height: "8rem" }}>
                      <img
                        src={hostImagePreview}
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "fill",
                          borderRadius: "4rem",
                        }}
                      />
                    </Box>
                    <Box
                      sx={{
                        position: "absolute",
                        top: "-.5rem",
                        right: "-1.5rem",
                      }}
                    >
                      <Button onClick={() => deleteHostImage()}>
                        <Suspense>
                          <IoMdCloseCircleOutline size={26} />
                        </Suspense>
                      </Button>
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              // mb: ".5rem",
              alignItems: "center",
              // border: "1px solid red",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="h6" fontWeight={500}>
                Do you offer Breakfast
              </Typography>
            </Box>

            <FormControlLabel
              sx={{ pr: "0rem" }}
              control={
                <Switch
                  checked={offerBreakfast}
                  onChange={handleBreakfastChange}
                />
              }
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              // mb: ".5rem",
              alignItems: "center",
              // border: "1px solid red",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="h6" fontWeight={500}>
                Is your property have Parking facility
              </Typography>
            </Box>

            <FormControlLabel
              sx={{ pr: "0rem" }}
              control={
                <Switch checked={isParking} onChange={handleParkingChange} />
              }
            />
          </Box>
          <Box
            sx={{
              marginTop: ".5rem",
            }}
          >
            <TextField
              value={isStaffLanguage}
              onChange={(e) => setIsStaffLanguage(e.target.value)}
              label="Staff Languages"
            />
          </Box>
        </Box>
        <Box sx={{ width: "50%" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              // mb: ".5rem",
              alignItems: "center",
              // border: "1px solid red",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="h6" fontWeight={500}>
                Do you want rent your property as whole
              </Typography>
            </Box>

            <FormControlLabel
              sx={{ pr: "0rem" }}
              control={
                <Switch
                  checked={rentWholeProperty}
                  onChange={handleRentProperty}
                />
              }
            />
          </Box>

          {rentWholeProperty && (
            <>
              {/* <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  variant="h6"
                  fontWeight={500}
                  sx={{ fontSize: "1rem", mb: ".5rem" }}
                >
                  Daily
                </Typography>
                <TextField
                  // label="Property name"
                  variant="outlined"
                  autoComplete="false"
                  type="number"
                  name="email"
                  required
                  value={dailyPrice}
                  onChange={(e) => setDailyPrice(e.target.value)}
                  sx={{
                    width: "15rem",
                    "& .MuiInputBase-root": {
                      height: "3.5rem",
                      borderRadius: ".625rem",
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "row",
                          }}
                        >
                          <EuroSymbolIcon
                            sx={{
                              color: "#E87B25",
                              cursor: "pointer",
                            }}
                          />
                        </Box>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  variant="h6"
                  fontWeight={500}
                  sx={{ fontSize: "1rem", mb: ".5rem" }}
                >
                  Weekly
                </Typography>
                <TextField
                  // label="Property name"
                  variant="outlined"
                  autoComplete="false"
                  type="number"
                  name="email"
                  required
                  value={dailyWeekly}
                  onChange={(e) => setDailyWeekly(e.target.value)}
                  sx={{
                    width: "15rem",
                    "& .MuiInputBase-root": {
                      height: "3.5rem",
                      borderRadius: ".625rem",
                      overflow: "hidden",
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "row",
                          }}
                        >
                          <EuroSymbolIcon
                            sx={{
                              color: "#E87B25",
                              cursor: "pointer",
                            }}
                          />
                        </Box>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box> */}
              <Box
                sx={{
                  mb: "1rem",
                }}
              >
                <Typography
                  variant="h6"
                  fontWeight={500}
                  sx={{ fontSize: "1rem", mb: ".5rem" }}
                >
                  how many Guests can stay in your Property
                </Typography>
                <TextField
                  variant="outlined"
                  autoComplete="false"
                  type="number"
                  name="email"
                  required
                  value={numberOfPersons}
                  onChange={(e) => setNumberOfPersons(e.target.value)}
                  sx={{
                    width: "15rem",
                    "& .MuiInputBase-root": {
                      height: "3.5rem",
                      borderRadius: ".625rem",
                      overflow: "hidden",
                    },
                  }}
                />
                <Box sx={{ mt: "2rem" }}>
                  {Array.from({ length: numberOfPersons }).map((_, index) => (
                    <Box
                      key={index}
                      sx={{
                        marginBottom: "1rem",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <BsFillPersonFill size={20} /> * {index + 1} price:
                      <TextField
                        variant="outlined"
                        autoComplete="false"
                        type="number"
                        name="email"
                        value={personPrices[index]?.price || ""}
                        onChange={(e) =>
                          handlePriceChange(index, e.target.value)
                        }
                        sx={{
                          width: "8rem",
                          "& .MuiInputBase-root": {
                            height: "3.5rem",
                            borderRadius: ".625rem",
                            overflow: "hidden",
                          },
                          ml: "1rem",
                        }}
                      />
                      {/* <input
                          type="number"
                          value={personPrices[index]?.price || ""}
                          onChange={(e) =>
                            handlePriceChange(index, e.target.value)
                          }
                          style={{marginLeft:"1rem"}}
                        /> */}
                    </Box>
                  ))}
                  {/* <pre>{JSON.stringify(personPrices, null, 2)}</pre> */}
                </Box>
              </Box>
            </>
          )}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              alignItems: "center",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="h6" fontSize={"1rem"} fontWeight={500}>
                Do you want to rent your rooms
              </Typography>
            </Box>

            <FormControlLabel
              sx={{ pr: "0rem" }}
              control={
                <Switch checked={rentRooms} onChange={handleRentRooms} />
              }
            />
          </Box>
        </Box>
      </Box>
    </Box>,

    // step 8
    <Box sx={{ mt: "1rem" }}>
      <Typography
        variant="h6"
        sx={{ fontSize: "1.25rem", color: "black.main" }}
      >
        <Typography
          variant="caption"
          fontWeight={600}
          sx={{ color: "#E87B25", fontSize: "1.25rem" }}
        >
          Step 8 :&nbsp;
        </Typography>
        Describe your space
      </Typography>

      <Box
        sx={{
          width: "100%",
          mt: "1rem",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box
          flexShrink={0}
          sx={{
            width: { xs: "100%", sm: "60%", md: "60%" },
            px: { xs: "0", sm: "3rem", md: "3rem" },
            mt: { xs: "1rem", sm: "2rem", md: "2rem" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              mb: "1rem",
            }}
          >
            <Box>
              <Typography
                variant="h6"
                fontWeight={500}
                sx={{ fontSize: "1rem", mt: ".3rem", mb: ".5rem" }}
              >
                Rooms
              </Typography>
            </Box>
            <Box>
              <Button
                onClick={() => setOpenRoomDialog(true)}
                sx={{
                  backgroundColor: "#232674",
                  color: "White",
                  textTransform: "none",
                  borderRadius: "10px",
                  paddingX: ".5rem",
                  "&:hover": {
                    backgroundColor: "#232674",
                    color: "White",
                  },
                }}
              >
                <Typography variant="body1" fontSize={14}>
                  Add Bedrooms
                </Typography>
              </Button>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              mb: "1rem",
            }}
          >
            <Typography
              variant="h6"
              fontWeight={500}
              sx={{ fontSize: "1rem", mb: ".5rem" }}
            >
              Bathrooms
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <IconButton
                disabled={bathrooms === 1}
                onClick={() => setBathrooms(bathrooms - 1)}
              >
                <RemoveCircleOutlineIcon />
              </IconButton>
              <Typography
                fontWeight={400}
                sx={{
                  fontSize: "1rem",
                  color: "primaryText.main",
                  mx: ".7rem",
                }}
              >
                {bathrooms}
              </Typography>
              <IconButton onClick={() => setBathrooms(bathrooms + 1)}>
                <ControlPointIcon />
              </IconButton>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              mb: "1rem",
            }}
          >
            <Typography
              variant="h6"
              fontWeight={500}
              sx={{ fontSize: "1rem", mb: ".5rem" }}
            >
              Kitchen
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <IconButton
                disabled={kitchen <= 0}
                onClick={() => setKitchen(kitchen - 1)}
              >
                <RemoveCircleOutlineIcon />
              </IconButton>
              <Typography
                fontWeight={400}
                sx={{
                  fontSize: "1rem",
                  color: "primaryText.main",
                  mx: ".7rem",
                }}
              >
                {kitchen}
              </Typography>
              <IconButton onClick={() => setKitchen(kitchen + 1)}>
                <ControlPointIcon />
              </IconButton>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: { xs: "top", sm: "center", md: "center" },
              justifyContent: "space-between",
              mb: "1rem",
            }}
          >
            <Box
              sx={{
                width: { xs: "50%", sm: "60%", md: "60%" },
              }}
            >
              <Typography
                variant="h6"
                fontWeight={500}
                sx={{ fontSize: "1rem", mb: ".5rem" }}
              >
                Cots
              </Typography>
              <Typography
                variant="body1"
                sx={{ fontSize: "12px", mb: ".5rem" }}
              >
                Cots sleep most infants 0-3 and can be made available to guests
                on request.
              </Typography>
            </Box>
            <Box>
              <FormControl>
                <Grid container spacing={2}>
                  <Grid item>
                    <RadioGroup
                      aria-label="radio-options"
                      name="radio-options"
                      value={cotValue}
                      onChange={handleCotValue}
                      sx={{ display: "flex", flexDirection: "row" }}
                    >
                      <FormControlLabel
                        value="false"
                        control={<Radio />}
                        label="No"
                      />
                      <FormControlLabel
                        value="true"
                        control={<Radio />}
                        label="Yes"
                      />
                    </RadioGroup>
                  </Grid>
                </Grid>
              </FormControl>
            </Box>
          </Box>
          <Dialog
            open={openRoomDialog}
            sx={{
              "& .MuiDialog-paper": {
                width: { xs: "80%", sm: "35%", md: "35%" },
                height: {
                  xs: "auto",
                  sm: moreBeds ? "auto" : "auto",
                  md: moreBeds ? "auto" : "auto",
                },
                marginBottom: "1rem",
              },
            }}
          >
            <DialogTitle>Add Bedroom</DialogTitle>
            <DialogContent>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <TextField
                  label="Bedroom Name"
                  variant="outlined"
                  autoComplete="false"
                  type="text"
                  name="rooms_name"
                  value={bedroomName}
                  onChange={(e) => setBedroomName(e.target.value)}
                  required
                  sx={{
                    width: { xs: "100%", sm: "60%", md: "60%" },
                    "& .MuiInputBase-root": {
                      height: "3.5rem",
                      borderRadius: ".625rem",
                    },
                  }}
                />

                {!propertyData?.whole_property && (
                  <TextField
                    label="Price"
                    variant="outlined"
                    autoComplete="false"
                    type="text"
                    name="price"
                    required
                    value={bedroomPrice}
                    onChange={(e) => setBedroomPrice(e.target.value)}
                    sx={{
                      width: { xs: "100%", sm: "30%", md: "30%" },
                      "& .MuiInputBase-root": {
                        height: "3.5rem",
                        borderRadius: ".625rem",
                      },
                    }}
                  />
                )}
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mt: "1rem",
                }}
              >
                <TextField
                  label="Bedroom Description"
                  variant="outlined"
                  autoComplete="false"
                  type="text"
                  name="description"
                  multiline
                  rows={4}
                  required
                  value={bedroomDescription}
                  onChange={(e) => setBedroomDescription(e.target.value)}
                  sx={{
                    width: { xs: "100%", sm: "70%", md: "70%" },
                    "& .MuiInputBase-root": {
                      height: "auto",
                      borderRadius: ".625rem",
                    },
                  }}
                />

                <TextField
                  label="Size"
                  variant="outlined"
                  autoComplete="false"
                  type="text"
                  name="room_size"
                  required
                  value={bedroomSize}
                  onChange={(e) => setBedroomSize(e.target.value)}
                  sx={{
                    width: { xs: "100%", sm: "20%", md: "20%" },
                    "& .MuiInputBase-root": {
                      height: "3.5rem",
                      borderRadius: ".625rem",
                    },
                  }}
                />
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mt: "1rem",
                  width: "90%",
                }}
              >
                <Typography
                  variant="h6"
                  fontWeight={500}
                  sx={{ fontSize: ".9rem", mb: ".5rem" }}
                >
                  How many guests can stay?
                </Typography>
                <Accordion
                  sx={{
                    backgroundColor: "transparent",
                    zIndex: 2,
                    width: {
                      xs: "5rem",
                      sm: "8rem",
                      md: "6.9rem",
                    },
                  }}
                  expanded={expanded === "panel1"}
                  onChange={handleAccordionChange("panel1")}
                >
                  <AccordionSummary
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: "0",
                      "& .MuiAccordionSummary-content": {
                        m: "0rem",
                      },
                    }}
                    aria-controls="panel1d-content"
                    id="panel1d-header"
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Typography
                        variant="body1"
                        sx={{
                          fontSize: { xs: ".8rem", sm: "1rem", md: "1rem" },
                          lineHeight: "1rem",
                          color: "primaryText.main",
                          paddingBottom: {
                            xs: ".3rem",
                            sm: ".8rem",
                            md: ".8rem",
                          },
                        }}
                      >
                        Guests
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{
                          fontSize: { xs: ".8rem", sm: "1rem", md: "1rem" },
                          lineHeight: "1.25rem",
                          color: "secondaryText.main",
                        }}
                      >
                        {adult + children} guests
                      </Typography>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails
                    sx={{
                      border: "1px solid #E7E7E7",
                      backgroundColor: "white.main",
                      borderRadius: ".5rem",
                      width: { xs: "11rem" },
                      mt: "0rem",
                      ml: { xs: "0", sm: "0", md: "-2.5rem" },
                      display: "flex",
                      flexDirection: "column",
                      p: ".5rem",
                      gap: ".5rem",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Typography
                          variant="h6"
                          fontWeight={500}
                          sx={{ fontSize: "1rem", color: "primaryText.main" }}
                        >
                          Adults
                        </Typography>
                        <Typography
                          variant="body2"
                          fontWeight={400}
                          sx={{
                            fontSize: ".8rem",
                            color: "secondaryText.main",
                          }}
                        >
                          Age 13+
                        </Typography>
                      </Box>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <IconButton
                          onClick={() => setAdult(adult - 1)}
                          disabled={adult === 0}
                          size="small"
                          sx={{
                            border: "1px solid #E7E7E7",
                            height: "2rem",
                            width: "2rem",
                            "&:hover": {
                              border: "1px solid #000",
                              transition: ".3s",
                            },
                          }}
                        >
                          <RemoveIcon
                            sx={{
                              color: "ternaryText.main",
                              "&:hover": {
                                color: "#000",
                                transition: ".3s",
                              },
                            }}
                          />
                        </IconButton>
                        <Typography
                          fontWeight={400}
                          variant="body2"
                          sx={{
                            fontSize: "1rem",
                            color: "primaryText.main",
                            mx: ".5rem",
                          }}
                        >
                          {adult}
                        </Typography>
                        <IconButton
                          onClick={() => setAdult(adult + 1)}
                          disabled={adult === 13}
                          size="small"
                          sx={{
                            border: "1px solid #E7E7E7",
                            height: "2rem",
                            width: "2rem",
                            "&:hover": {
                              border: "1px solid #000",
                              transition: ".3s",
                            },
                          }}
                        >
                          <AddIcon
                            sx={{
                              color: "ternaryText.main",
                              "&:hover": {
                                color: "#000",
                                transition: ".3s",
                              },
                            }}
                          />
                        </IconButton>
                      </Box>
                    </Box>
                    <Divider />

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Typography
                          variant="h6"
                          fontWeight={500}
                          sx={{ fontSize: "1rem", color: "primaryText.main" }}
                        >
                          Children
                        </Typography>
                        <Typography
                          variant="body2"
                          fontWeight={400}
                          sx={{
                            fontSize: ".8rem",
                            color: "secondaryText.main",
                          }}
                        >
                          Ages 2–12
                        </Typography>
                      </Box>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <IconButton
                          onClick={() => setChildren(children - 1)}
                          disabled={children === 0}
                          size="small"
                          sx={{
                            border: "1px solid #E7E7E7",
                            height: "2rem",
                            width: "2rem",
                            "&:hover": {
                              border: "1px solid #000",
                              transition: ".3s",
                            },
                          }}
                        >
                          <RemoveIcon
                            sx={{
                              color: "ternaryText.main",
                              "&:hover": {
                                color: "#000",
                                transition: ".3s",
                              },
                            }}
                          />
                        </IconButton>
                        <Typography
                          fontWeight={400}
                          variant="body2"
                          sx={{
                            fontSize: "1rem",
                            color: "primaryText.main",
                            mx: ".5rem",
                          }}
                        >
                          {children}
                        </Typography>
                        <IconButton
                          onClick={() => setChildren(children + 1)}
                          disabled={children === 6}
                          size="small"
                          sx={{
                            border: "1px solid #E7E7E7",
                            height: "2rem",
                            width: "2rem",
                            "&:hover": {
                              border: "1px solid #000",
                              transition: ".3s",
                            },
                          }}
                        >
                          <AddIcon
                            sx={{
                              color: "ternaryText.main",
                              "&:hover": {
                                color: "#000",
                                transition: ".3s",
                              },
                            }}
                          />
                        </IconButton>
                      </Box>
                    </Box>
                    <Divider />

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        mb: "1rem",
                      }}
                    >
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Typography
                          variant="h6"
                          fontWeight={500}
                          sx={{ fontSize: "1rem", color: "primaryText.main" }}
                        >
                          Pets
                        </Typography>
                        <Typography
                          variant="body2"
                          fontWeight={400}
                          sx={{
                            fontSize: ".8rem",
                            color: "secondaryText.main",
                          }}
                        >
                          Bringing a service animal?
                        </Typography>
                      </Box>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <IconButton
                          onClick={() => setPets(pets - 1)}
                          disabled={pets === 0}
                          size="small"
                          sx={{
                            border: "1px solid #E7E7E7",
                            height: "2rem",
                            width: "2rem",
                            "&:hover": {
                              border: "1px solid #000",
                              transition: ".3s",
                            },
                          }}
                        >
                          <RemoveIcon
                            sx={{
                              color: "ternaryText.main",
                              "&:hover": {
                                color: "#000",
                                transition: ".3s",
                              },
                            }}
                          />
                        </IconButton>
                        <Typography
                          fontWeight={400}
                          variant="body2"
                          sx={{
                            fontSize: "1rem",
                            color: "primaryText.main",
                            mx: ".5rem",
                          }}
                        >
                          {pets}
                        </Typography>
                        <IconButton
                          onClick={() => setPets(pets + 1)}
                          disabled={pets === 1}
                          size="small"
                          sx={{
                            border: "1px solid #E7E7E7",
                            height: "2rem",
                            width: "2rem",
                            "&:hover": {
                              border: "1px solid #000",
                              transition: ".3s",
                            },
                          }}
                        >
                          <AddIcon
                            sx={{
                              color: "ternaryText.main",
                              "&:hover": {
                                color: "#000",
                                transition: ".3s",
                              },
                            }}
                          />
                        </IconButton>
                      </Box>
                    </Box>
                    <Box sx={{ textAlign: "end" }}>
                      <Button
                        onClick={() => setExpanded(false)}
                        variant="text"
                        sx={{
                          textDecoration: "underline",
                          textTransform: "capitalize",
                          borderRadius: "1rem",
                          "&:hover": {
                            textDecoration: "underline",
                          },
                        }}
                      >
                        Close
                      </Button>
                    </Box>
                  </AccordionDetails>
                </Accordion>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mt: "1rem",
                }}
              >
                <Button
                  onClick={() => setOpenAmeitiesDialog(true)}
                  sx={{
                    backgroundColor: "#232674",
                    color: "White",
                    textTransform: "none",
                    borderRadius: "10px",
                    paddingX: ".5rem",
                    "&:hover": {
                      backgroundColor: "#232674",
                      color: "White",
                    },
                  }}
                >
                  Add Amenities
                </Button>

                <Button
                  onClick={() => setOpenRoomImagesDialog(true)}
                  sx={{
                    backgroundColor: "#232674",
                    color: "White",
                    textTransform: "none",
                    borderRadius: "10px",
                    paddingX: ".5rem",
                    "&:hover": {
                      backgroundColor: "#232674",
                      color: "White",
                    },
                  }}
                >
                  Add Images
                </Button>
              </Box>

              <Box
                sx={{
                  marginTop: { xs: ".5rem", sm: "1rem", md: "1rem" },
                }}
              >
                <Box>
                  <Typography
                    sx={{
                      fontSize: { xs: "13px", sm: "15px", md: "15px" },
                    }}
                  >
                    Which beds are available in this room?
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "1rem",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Suspense>
                        <LuBedSingle size={iconSize} />
                      </Suspense>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        marginLeft: "1rem",
                      }}
                    >
                      <Typography variant="body1" fontSize={14}>
                        Single Bed
                      </Typography>
                      <Typography variant="body2" fontSize={12}>
                        90 - 130 cm wide
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        border: "1px solid black",
                        borderRadius: "5px",
                        height: "45px",
                      }}
                    >
                      <IconButton
                        disabled={singlebed === 0}
                        onClick={() => setSinglebed(singlebed - 1)}
                      >
                        <RemoveCircleOutlineIcon />
                      </IconButton>
                      <Typography variant="body1" fontSize={13}>
                        {singlebed}
                      </Typography>
                      <IconButton onClick={() => setSinglebed(singlebed + 1)}>
                        <ControlPointIcon />
                      </IconButton>
                    </Box>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "1.5rem",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Suspense>
                        <LuBedDouble size={iconSize} />
                      </Suspense>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        marginLeft: "1rem",
                      }}
                    >
                      <Typography variant="body1" fontSize={14}>
                        Double Bed
                      </Typography>
                      <Typography variant="body2" fontSize={12}>
                        131 - 150 cm wide
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        border: "1px solid black",
                        borderRadius: "5px",
                        height: "45px",
                      }}
                    >
                      <IconButton
                        disabled={doublebed === 0}
                        onClick={() => setDOublebed(doublebed - 1)}
                      >
                        <RemoveCircleOutlineIcon />
                      </IconButton>
                      <Typography variant="body1" fontSize={13}>
                        {doublebed}
                      </Typography>
                      <IconButton onClick={() => setDOublebed(doublebed + 1)}>
                        <ControlPointIcon />
                      </IconButton>
                    </Box>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "1.5rem",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Suspense>
                        <BiBed size={iconSize} />
                      </Suspense>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        marginLeft: "1rem",
                      }}
                    >
                      <Typography variant="body1" fontSize={14}>
                        {`Large bed (King size)`}
                      </Typography>
                      <Typography variant="body2" fontSize={12}>
                        151 - 180 cm wide
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        border: "1px solid black",
                        borderRadius: "5px",
                        height: "45px",
                      }}
                    >
                      <IconButton
                        disabled={largebed === 0}
                        onClick={() => setLargebed(largebed - 1)}
                      >
                        <RemoveCircleOutlineIcon />
                      </IconButton>
                      <Typography variant="body1" fontSize={13}>
                        {largebed}
                      </Typography>
                      <IconButton onClick={() => setLargebed(largebed + 1)}>
                        <ControlPointIcon />
                      </IconButton>
                    </Box>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "1.5rem",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Suspense>
                        <BiBed size={iconSize} />
                      </Suspense>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        marginLeft: "1rem",
                      }}
                    >
                      <Typography variant="body1" fontSize={14}>
                        {`Extra-large double bed (Super-king )`}
                      </Typography>
                      <Typography variant="body2" fontSize={12}>
                        181 - 210 cm wide
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        border: "1px solid black",
                        borderRadius: "5px",
                        height: "45px",
                      }}
                    >
                      <IconButton
                        disabled={extraLargebed === 0}
                        onClick={() => setextraLargeBed(extraLargebed - 1)}
                      >
                        <RemoveCircleOutlineIcon />
                      </IconButton>
                      <Typography variant="body1" fontSize={13}>
                        {extraLargebed}
                      </Typography>
                      <IconButton
                        onClick={() => setextraLargeBed(extraLargebed + 1)}
                      >
                        <ControlPointIcon />
                      </IconButton>
                    </Box>
                  </Box>
                </Box>
                <Box
                  onClick={() => setMoreBeds(!moreBeds)}
                  sx={{
                    marginTop: ".5rem",
                    "&:hover": {
                      cursor: "pointer",
                    },
                  }}
                >
                  <Typography
                    variant="body1"
                    fontSize={13}
                    sx={{
                      color: "#0971e5",
                    }}
                  >
                    {moreBeds ? "> Fewer bed options" : "> More bed options"}
                  </Typography>
                </Box>
                {moreBeds && (
                  <>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "1.5rem",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Suspense>
                            <BiBed size={iconSize} />
                          </Suspense>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            marginLeft: "1rem",
                          }}
                        >
                          <Typography variant="body1" fontSize={14}>
                            Bunk Bed
                          </Typography>
                          <Typography variant="body2" fontSize={12}>
                            variable size
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            border: "1px solid black",
                            borderRadius: "5px",
                            height: "45px",
                          }}
                        >
                          <IconButton
                            disabled={bunkbed === 0}
                            onClick={() => setBunkbed(bunkbed - 1)}
                          >
                            <RemoveCircleOutlineIcon />
                          </IconButton>
                          <Typography variant="body1" fontSize={13}>
                            {bunkbed}
                          </Typography>
                          <IconButton onClick={() => setBunkbed(bunkbed + 1)}>
                            <ControlPointIcon />
                          </IconButton>
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "1.5rem",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Suspense>
                            <LuSofa size={iconSize} />
                          </Suspense>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            marginLeft: "1rem",
                          }}
                        >
                          <Typography variant="body1" fontSize={14}>
                            Sofa bed
                          </Typography>
                          <Typography variant="body2" fontSize={12}>
                            Variable size
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            border: "1px solid black",
                            borderRadius: "5px",
                            height: "45px",
                          }}
                        >
                          <IconButton
                            disabled={sofabed === 0}
                            onClick={() => setSofabed(sofabed - 1)}
                          >
                            <RemoveCircleOutlineIcon />
                          </IconButton>
                          <Typography variant="body1" fontSize={13}>
                            {sofabed}
                          </Typography>
                          <IconButton onClick={() => setSofabed(sofabed + 1)}>
                            <ControlPointIcon />
                          </IconButton>
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "1.5rem",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Suspense>
                            <BiBed size={iconSize} />
                          </Suspense>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            marginLeft: "1rem",
                          }}
                        >
                          <Typography variant="body1" fontSize={14}>
                            Futon Mat
                          </Typography>
                          <Typography variant="body2" fontSize={12}>
                            Variable size
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            border: "1px solid black",
                            borderRadius: "5px",
                            height: "45px",
                          }}
                        >
                          <IconButton
                            disabled={futonMet === 0}
                            onClick={() => setFutonMet(futonMet - 1)}
                          >
                            <RemoveCircleOutlineIcon />
                          </IconButton>
                          <Typography variant="body1" fontSize={13}>
                            {futonMet}
                          </Typography>
                          <IconButton onClick={() => setFutonMet(futonMet + 1)}>
                            <ControlPointIcon />
                          </IconButton>
                        </Box>
                      </Box>
                    </Box>
                  </>
                )}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "1rem",
                  }}
                >
                  <Box
                    sx={{
                      width: { xs: "33", sm: "28%", md: "28%" },
                    }}
                  >
                    <Button
                      onClick={() => handleCloseRoomDialog()}
                      sx={{
                        width: "100%",
                        textTransform: "none",
                      }}
                    >
                      <Typography
                        variant="body1"
                        sx={{
                          fontSize: { xs: "12px", sm: "15px", md: "15px" },
                        }}
                      >
                        {"< Cancel"}
                      </Typography>
                    </Button>
                  </Box>
                  <Box
                    sx={{
                      width: { xs: "63%", sm: "68%", md: "68%" },
                    }}
                  >
                    <Button
                      onClick={() => handleSaveRoomData()}
                      sx={{
                        width: "100%",
                        backgroundColor: "#006ce4",
                        color: "White",
                        textTransform: "none",
                        "&:hover": {
                          backgroundColor: "#006ce4",
                          color: "White",
                        },
                      }}
                    >
                      <Typography
                        variant="body1"
                        sx={{
                          fontSize: { xs: "12px", sm: "15px", md: "15px" },
                        }}
                      >
                        Save
                      </Typography>
                    </Button>
                  </Box>
                </Box>
              </Box>
            </DialogContent>
          </Dialog>

          {/* Amenities DialogBox */}

          <Dialog
            open={openAmenitiesDialog}
            maxWidth={false}
            sx={{
              "& .MuiDialog-paper": {
                width: { xs: "80%", sm: "80%", md: "80%" },
                marginBottom: "1rem",
              },
            }}
          >
            <DialogTitle>Add Amenities for this Room</DialogTitle>
            <DialogContent>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "33%",
                  }}
                >
                  {propertyData?.amenities &&
                    part1?.map((ele, i) => (
                      <Box
                        key={i}
                        sx={{
                          mb: "1rem",
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: "bold",
                          }}
                        >
                          {ele?.name}
                        </Typography>
                        {ele?.subamenities && ele?.subamenities?.length > 0 && (
                          <Box>
                            {ele?.subamenities?.map((subAmenity, subIndex) => (
                              <FormGroup key={subIndex}>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      onChange={(event) =>
                                        handleRoomAmenities(
                                          event,
                                          subAmenity?.id
                                        )
                                      }
                                    />
                                  }
                                  label={
                                    <Typography
                                      sx={{
                                        fontSize: "14px", // Adjust the padding of the label
                                      }}
                                    >
                                      {subAmenity?.name}
                                    </Typography>
                                  }
                                />
                              </FormGroup>
                            ))}
                          </Box>
                        )}
                      </Box>
                    ))}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "33%",
                  }}
                >
                  {propertyData?.amenities &&
                    part2?.map((ele, i) => (
                      <Box
                        key={i}
                        sx={{
                          mb: "1rem",
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: "bold",
                          }}
                        >
                          {ele?.name}
                        </Typography>
                        {ele?.subamenities && ele?.subamenities?.length > 0 && (
                          <Box>
                            {ele?.subamenities?.map((subAmenity, subIndex) => (
                              <FormControlLabel
                                key={subIndex}
                                control={
                                  <Checkbox
                                    value={subAmenity?.name}
                                    onChange={(event) =>
                                      handleRoomAmenities(event, subAmenity?.id)
                                    }
                                  />
                                }
                                label={subAmenity?.name}
                              />
                            ))}
                          </Box>
                        )}
                      </Box>
                    ))}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "33%",
                  }}
                >
                  {propertyData?.amenities &&
                    part3?.map((ele, i) => (
                      <Box
                        key={i}
                        sx={{
                          mb: "1rem",
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: "bold",
                          }}
                        >
                          {ele?.name}
                        </Typography>
                        {ele?.subamenities && ele?.subamenities?.length > 0 && (
                          <Box>
                            {ele?.subamenities?.map((subAmenity, subIndex) => (
                              <FormControlLabel
                                key={subIndex}
                                control={
                                  <Checkbox
                                    value={subAmenity?.name}
                                    onChange={(event) =>
                                      handleRoomAmenities(event, subAmenity?.id)
                                    }
                                  />
                                }
                                label={subAmenity?.name}
                              />
                            ))}
                          </Box>
                        )}
                      </Box>
                    ))}
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "1rem",
                }}
              >
                <Box
                  sx={{
                    width: { xs: "33", sm: "28%", md: "28%" },
                  }}
                >
                  <Button
                    onClick={() => setOpenAmeitiesDialog(false)}
                    sx={{
                      width: "100%",
                      textTransform: "none",
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: { xs: "12px", sm: "15px", md: "15px" },
                      }}
                    >
                      {"< Cancel"}
                    </Typography>
                  </Button>
                </Box>
                <Box
                  sx={{
                    width: { xs: "63%", sm: "68%", md: "68%" },
                  }}
                >
                  <Button
                    onClick={() => setOpenAmeitiesDialog(false)}
                    sx={{
                      width: "100%",
                      backgroundColor: "#006ce4",
                      color: "White",
                      textTransform: "none",
                      "&:hover": {
                        backgroundColor: "#006ce4",
                        color: "White",
                      },
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: { xs: "12px", sm: "15px", md: "15px" },
                      }}
                    >
                      Save
                    </Typography>
                  </Button>
                </Box>
              </Box>
            </DialogContent>
          </Dialog>

          {/* Room Images DialogBox */}

          <Dialog
            open={openRoomImagesDialog}
            maxWidth={false}
            sx={{
              "& .MuiDialog-paper": {
                width: { xs: "80%", sm: "80%", md: "80%" },
                marginBottom: "1rem",
              },
            }}
          >
            <DialogTitle>Add Images</DialogTitle>
            <DialogContent>
              <>
                <Box>
                  {/* {previewData?.property_images && previewData?.property_images.length > 0 && 
                    previewData?.property_images.map((row, rowIndex) => (
                      <Box key={rowIndex} sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }} >
                        
                        {row.map((url, index) => (
                          <Box key={index} sx={{ display: 'flex', alignItems: 'center', marginRight: '10px' }}>
                          <img
                            src={url}
                            alt={`Image ${rowIndex * 4 + index + 1}`}
                            style={{ width: 'calc(25% - 10px)', height: 'auto', marginRight: '10px' }}
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={selectedImages.includes(url)}
                                onChange={handleRoomImages(url)}
                              />
                            }
                            label=""
                          />
                        </Box>
                        ))}
                      </Box>
                    ))
                    } */}
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "repeat(4, 1fr)",
                      gap: 2, // Equivalent to 16px
                    }}
                  >
                    {propertyData?.property_images?.map((image, index) => (
                      <Box
                        key={image.id}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 2, // Space between checkbox and image
                        }}
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={(e) =>
                                handleCheckboxChange(e, image.img2)
                              }
                              checked={selectedImages.includes(image.img2)}
                            />
                          }
                          label=""
                        />
                        <Box
                          component="img"
                          src={image.img2}
                          alt={`property-${index + 1}`}
                          sx={{
                            width: "calc(60% - 10px)",
                            height: "auto",
                            objectFit: "cover",
                            marginRight: "10px",
                          }}
                        />
                      </Box>
                    ))}
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "1rem",
                  }}
                >
                  <Box
                    sx={{
                      width: { xs: "33", sm: "28%", md: "28%" },
                    }}
                  >
                    <Button
                      onClick={() => setOpenRoomImagesDialog(false)}
                      sx={{
                        width: "100%",
                        textTransform: "none",
                      }}
                    >
                      <Typography
                        variant="body1"
                        sx={{
                          fontSize: { xs: "12px", sm: "15px", md: "15px" },
                        }}
                      >
                        {"< Cancel"}
                      </Typography>
                    </Button>
                  </Box>
                  <Box
                    sx={{
                      width: { xs: "63%", sm: "68%", md: "68%" },
                    }}
                  >
                    <Button
                      onClick={() => setOpenRoomImagesDialog(false)}
                      sx={{
                        width: "100%",
                        backgroundColor: "#006ce4",
                        color: "White",
                        textTransform: "none",
                        "&:hover": {
                          backgroundColor: "#006ce4",
                          color: "White",
                        },
                      }}
                    >
                      <Typography
                        variant="body1"
                        sx={{
                          fontSize: { xs: "12px", sm: "15px", md: "15px" },
                        }}
                      >
                        Save
                      </Typography>
                    </Button>
                  </Box>
                </Box>
              </>
            </DialogContent>
          </Dialog>
        </Box>
        <Box
          flex={1}
          sx={{
            width: "40%",
            px: "1rem",
            mt: "2rem",
          }}
        >
          {rooms.length > 0 && (
            <Box
              sx={{
                border: "1px solid black",
                borderRadius: "10px",
                padding: "1rem",
                pt: "0",
                height: "375px",
                overflowY: "auto",
                "&::-webkit-scrollbar": {
                  width: "8px",
                  borderRadius: "10px",
                },
                "&::-webkit-scrollbar-thumb": {
                  borderRadius: "10px",
                },
                "&::-webkit-scrollbar-track": {
                  borderRadius: "10px",
                  my: "5px",
                },
              }}
            >
              {rooms?.map((ele, index) => (
                <Box
                  key={index}
                  sx={{
                    boxShadow: "0 0 10px 0 rgba(0,0,0,0.1)",
                    borderRadius: "5px",
                    backgroundColor: "White",
                    mt: "1rem",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box>
                      <Button
                        onClick={() => handleEditRoom(index)}
                        sx={{
                          height: "1.5rem",
                          minWidth: "unset",
                          borderRadius: ".75rem",
                          margin: ".2rem",
                          color: "White",
                          backgroundColor: "blue",
                          textTransform: "none",
                          "&:hover": {
                            color: "White",
                            backgroundColor: "blue",
                          },
                        }}
                      >
                        edit
                      </Button>
                    </Box>
                    <Button
                      onClick={() => deleteRoom(index)}
                      sx={{
                        height: "1.5rem",
                        width: "1.5rem",
                        minWidth: "unset",
                        border: "2px solid grey",
                        borderRadius: ".75rem",
                        margin: ".2rem",
                        color: "grey",
                      }}
                    >
                      x
                    </Button>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      px: ".5rem",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: ".9rem",
                        fontWeight: "bold",
                      }}
                    >
                      {ele.rooms_name}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: ".9rem",
                        fontWeight: "bold",
                      }}
                    >
                      ${ele.price}
                    </Typography>
                  </Box>
                  {ele.bed_type?.map((beds, index) => (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "70%",
                        px: ".5rem",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: ".7rem",
                        }}
                      >
                        {beds.name}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: ".7rem",
                        }}
                      >
                        {beds.number}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              ))}
            </Box>
          )}
          <Dialog
            open={openEditRoomDialog}
            sx={{
              "& .MuiDialog-paper": {
                width: { xs: "80%", sm: "35%", md: "35%" },
                height: {
                  xs: "auto",
                  sm: moreBeds ? "auto" : "auto",
                  md: moreBeds ? "auto" : "auto",
                },
                marginBottom: "1rem",
              },
            }}
          >
            <DialogTitle>Edit Bedroom</DialogTitle>
            <DialogContent>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <TextField
                  label="Bedroom Name"
                  variant="outlined"
                  autoComplete="false"
                  type="text"
                  name="rooms_name"
                  value={bedroomName}
                  onChange={(e) => setBedroomName(e.target.value)}
                  required
                  sx={{
                    width: { xs: "100%", sm: "60%", md: "60%" },
                    "& .MuiInputBase-root": {
                      height: "3.5rem",
                      borderRadius: ".625rem",
                    },
                  }}
                />

                {!propertyData?.whole_property && (
                  <TextField
                    label="Price"
                    variant="outlined"
                    autoComplete="false"
                    type="text"
                    name="price"
                    required
                    value={bedroomPrice}
                    onChange={(e) => setBedroomPrice(e.target.value)}
                    sx={{
                      width: { xs: "100%", sm: "30%", md: "30%" },
                      "& .MuiInputBase-root": {
                        height: "3.5rem",
                        borderRadius: ".625rem",
                      },
                    }}
                  />
                )}
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mt: "1rem",
                }}
              >
                <TextField
                  label="Bedroom Description"
                  variant="outlined"
                  autoComplete="false"
                  type="text"
                  name="description"
                  multiline
                  rows={4}
                  required
                  value={bedroomDescription}
                  onChange={(e) => setBedroomDescription(e.target.value)}
                  sx={{
                    width: { xs: "100%", sm: "70%", md: "70%" },
                    "& .MuiInputBase-root": {
                      height: "auto",
                      borderRadius: ".625rem",
                    },
                  }}
                />

                <TextField
                  label="Size"
                  variant="outlined"
                  autoComplete="false"
                  type="text"
                  name="room_size"
                  required
                  value={bedroomSize}
                  onChange={(e) => setBedroomSize(e.target.value)}
                  sx={{
                    width: { xs: "100%", sm: "20%", md: "20%" },
                    "& .MuiInputBase-root": {
                      height: "3.5rem",
                      borderRadius: ".625rem",
                    },
                  }}
                />
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mt: "1rem",
                  width: "90%",
                }}
              >
                <Typography
                  variant="h6"
                  fontWeight={500}
                  sx={{ fontSize: ".9rem", mb: ".5rem" }}
                >
                  How many guests can stay?
                </Typography>
                <Accordion
                  sx={{
                    backgroundColor: "transparent",
                    zIndex: 2,
                    width: {
                      xs: "5rem",
                      sm: "8rem",
                      md: "6.9rem",
                    },
                  }}
                  expanded={expanded === "panel1"}
                  onChange={handleAccordionChange("panel1")}
                >
                  <AccordionSummary
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: "0",
                      "& .MuiAccordionSummary-content": {
                        m: "0rem",
                      },
                    }}
                    aria-controls="panel1d-content"
                    id="panel1d-header"
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Typography
                        variant="body1"
                        sx={{
                          fontSize: { xs: ".8rem", sm: "1rem", md: "1rem" },
                          lineHeight: "1rem",
                          color: "primaryText.main",
                          paddingBottom: {
                            xs: ".3rem",
                            sm: ".8rem",
                            md: ".8rem",
                          },
                        }}
                      >
                        Guests
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{
                          fontSize: { xs: ".8rem", sm: "1rem", md: "1rem" },
                          lineHeight: "1.25rem",
                          color: "secondaryText.main",
                        }}
                      >
                        {adult + children} guests
                      </Typography>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails
                    sx={{
                      border: "1px solid #E7E7E7",
                      backgroundColor: "white.main",
                      borderRadius: ".5rem",
                      width: { xs: "11rem" },
                      mt: "0rem",
                      ml: { xs: "0", sm: "0", md: "-2.5rem" },
                      display: "flex",
                      flexDirection: "column",
                      p: ".5rem",
                      gap: ".5rem",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Typography
                          variant="h6"
                          fontWeight={500}
                          sx={{ fontSize: "1rem", color: "primaryText.main" }}
                        >
                          Adults
                        </Typography>
                        <Typography
                          variant="body2"
                          fontWeight={400}
                          sx={{
                            fontSize: ".8rem",
                            color: "secondaryText.main",
                          }}
                        >
                          Age 13+
                        </Typography>
                      </Box>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <IconButton
                          onClick={() => setAdult(adult - 1)}
                          disabled={adult === 0}
                          size="small"
                          sx={{
                            border: "1px solid #E7E7E7",
                            height: "2rem",
                            width: "2rem",
                            "&:hover": {
                              border: "1px solid #000",
                              transition: ".3s",
                            },
                          }}
                        >
                          <RemoveIcon
                            sx={{
                              color: "ternaryText.main",
                              "&:hover": {
                                color: "#000",
                                transition: ".3s",
                              },
                            }}
                          />
                        </IconButton>
                        <Typography
                          fontWeight={400}
                          variant="body2"
                          sx={{
                            fontSize: "1rem",
                            color: "primaryText.main",
                            mx: ".5rem",
                          }}
                        >
                          {adult}
                        </Typography>
                        <IconButton
                          onClick={() => setAdult(adult + 1)}
                          disabled={adult === 13}
                          size="small"
                          sx={{
                            border: "1px solid #E7E7E7",
                            height: "2rem",
                            width: "2rem",
                            "&:hover": {
                              border: "1px solid #000",
                              transition: ".3s",
                            },
                          }}
                        >
                          <AddIcon
                            sx={{
                              color: "ternaryText.main",
                              "&:hover": {
                                color: "#000",
                                transition: ".3s",
                              },
                            }}
                          />
                        </IconButton>
                      </Box>
                    </Box>
                    <Divider />

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Typography
                          variant="h6"
                          fontWeight={500}
                          sx={{ fontSize: "1rem", color: "primaryText.main" }}
                        >
                          Children
                        </Typography>
                        <Typography
                          variant="body2"
                          fontWeight={400}
                          sx={{
                            fontSize: ".8rem",
                            color: "secondaryText.main",
                          }}
                        >
                          Ages 2–12
                        </Typography>
                      </Box>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <IconButton
                          onClick={() => setChildren(children - 1)}
                          disabled={children === 0}
                          size="small"
                          sx={{
                            border: "1px solid #E7E7E7",
                            height: "2rem",
                            width: "2rem",
                            "&:hover": {
                              border: "1px solid #000",
                              transition: ".3s",
                            },
                          }}
                        >
                          <RemoveIcon
                            sx={{
                              color: "ternaryText.main",
                              "&:hover": {
                                color: "#000",
                                transition: ".3s",
                              },
                            }}
                          />
                        </IconButton>
                        <Typography
                          fontWeight={400}
                          variant="body2"
                          sx={{
                            fontSize: "1rem",
                            color: "primaryText.main",
                            mx: ".5rem",
                          }}
                        >
                          {children}
                        </Typography>
                        <IconButton
                          onClick={() => setChildren(children + 1)}
                          disabled={children === 6}
                          size="small"
                          sx={{
                            border: "1px solid #E7E7E7",
                            height: "2rem",
                            width: "2rem",
                            "&:hover": {
                              border: "1px solid #000",
                              transition: ".3s",
                            },
                          }}
                        >
                          <AddIcon
                            sx={{
                              color: "ternaryText.main",
                              "&:hover": {
                                color: "#000",
                                transition: ".3s",
                              },
                            }}
                          />
                        </IconButton>
                      </Box>
                    </Box>
                    <Divider />

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        mb: "1rem",
                      }}
                    >
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Typography
                          variant="h6"
                          fontWeight={500}
                          sx={{ fontSize: "1rem", color: "primaryText.main" }}
                        >
                          Pets
                        </Typography>
                        <Typography
                          variant="body2"
                          fontWeight={400}
                          sx={{
                            fontSize: ".8rem",
                            color: "secondaryText.main",
                          }}
                        >
                          Bringing a service animal?
                        </Typography>
                      </Box>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <IconButton
                          onClick={() => setPets(pets - 1)}
                          disabled={pets === 0}
                          size="small"
                          sx={{
                            border: "1px solid #E7E7E7",
                            height: "2rem",
                            width: "2rem",
                            "&:hover": {
                              border: "1px solid #000",
                              transition: ".3s",
                            },
                          }}
                        >
                          <RemoveIcon
                            sx={{
                              color: "ternaryText.main",
                              "&:hover": {
                                color: "#000",
                                transition: ".3s",
                              },
                            }}
                          />
                        </IconButton>
                        <Typography
                          fontWeight={400}
                          variant="body2"
                          sx={{
                            fontSize: "1rem",
                            color: "primaryText.main",
                            mx: ".5rem",
                          }}
                        >
                          {pets}
                        </Typography>
                        <IconButton
                          onClick={() => setPets(pets + 1)}
                          disabled={pets === 1}
                          size="small"
                          sx={{
                            border: "1px solid #E7E7E7",
                            height: "2rem",
                            width: "2rem",
                            "&:hover": {
                              border: "1px solid #000",
                              transition: ".3s",
                            },
                          }}
                        >
                          <AddIcon
                            sx={{
                              color: "ternaryText.main",
                              "&:hover": {
                                color: "#000",
                                transition: ".3s",
                              },
                            }}
                          />
                        </IconButton>
                      </Box>
                    </Box>
                    <Box sx={{ textAlign: "end" }}>
                      <Button
                        onClick={() => setExpanded(false)}
                        variant="text"
                        sx={{
                          textDecoration: "underline",
                          textTransform: "capitalize",
                          borderRadius: "1rem",
                          "&:hover": {
                            textDecoration: "underline",
                          },
                        }}
                      >
                        Close
                      </Button>
                    </Box>
                  </AccordionDetails>
                </Accordion>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mt: "1rem",
                }}
              >
                <Button
                  onClick={() => setOpenAmeitiesDialog(true)}
                  sx={{
                    backgroundColor: "#232674",
                    color: "White",
                    textTransform: "none",
                    borderRadius: "10px",
                    paddingX: ".5rem",
                    "&:hover": {
                      backgroundColor: "#232674",
                      color: "White",
                    },
                  }}
                >
                  Add Amenities
                </Button>

                <Button
                  onClick={() => setOpenRoomImagesDialog(true)}
                  sx={{
                    backgroundColor: "#232674",
                    color: "White",
                    textTransform: "none",
                    borderRadius: "10px",
                    paddingX: ".5rem",
                    "&:hover": {
                      backgroundColor: "#232674",
                      color: "White",
                    },
                  }}
                >
                  Add Images
                </Button>
              </Box>

              <Box
                sx={{
                  marginTop: { xs: ".5rem", sm: "1rem", md: "1rem" },
                }}
              >
                <Box>
                  <Typography
                    sx={{
                      fontSize: { xs: "13px", sm: "15px", md: "15px" },
                    }}
                  >
                    Which beds are available in this room?
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "1rem",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Suspense>
                        <LuBedSingle size={iconSize} />
                      </Suspense>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        marginLeft: "1rem",
                      }}
                    >
                      <Typography variant="body1" fontSize={14}>
                        Single Bed
                      </Typography>
                      <Typography variant="body2" fontSize={12}>
                        90 - 130 cm wide
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        border: "1px solid black",
                        borderRadius: "5px",
                        height: "45px",
                      }}
                    >
                      <IconButton
                        disabled={singlebed === 0}
                        onClick={() => setSinglebed(singlebed - 1)}
                      >
                        <RemoveCircleOutlineIcon />
                      </IconButton>
                      <Typography variant="body1" fontSize={13}>
                        {singlebed}
                      </Typography>
                      <IconButton onClick={() => setSinglebed(singlebed + 1)}>
                        <ControlPointIcon />
                      </IconButton>
                    </Box>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "1.5rem",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Suspense>
                        <LuBedDouble size={iconSize} />
                      </Suspense>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        marginLeft: "1rem",
                      }}
                    >
                      <Typography variant="body1" fontSize={14}>
                        Double Bed
                      </Typography>
                      <Typography variant="body2" fontSize={12}>
                        131 - 150 cm wide
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        border: "1px solid black",
                        borderRadius: "5px",
                        height: "45px",
                      }}
                    >
                      <IconButton
                        disabled={doublebed === 0}
                        onClick={() => setDOublebed(doublebed - 1)}
                      >
                        <RemoveCircleOutlineIcon />
                      </IconButton>
                      <Typography variant="body1" fontSize={13}>
                        {doublebed}
                      </Typography>
                      <IconButton onClick={() => setDOublebed(doublebed + 1)}>
                        <ControlPointIcon />
                      </IconButton>
                    </Box>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "1.5rem",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Suspense>
                        <BiBed size={iconSize} />
                      </Suspense>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        marginLeft: "1rem",
                      }}
                    >
                      <Typography variant="body1" fontSize={14}>
                        {`Large bed (King size)`}
                      </Typography>
                      <Typography variant="body2" fontSize={12}>
                        151 - 180 cm wide
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        border: "1px solid black",
                        borderRadius: "5px",
                        height: "45px",
                      }}
                    >
                      <IconButton
                        disabled={largebed === 0}
                        onClick={() => setLargebed(largebed - 1)}
                      >
                        <RemoveCircleOutlineIcon />
                      </IconButton>
                      <Typography variant="body1" fontSize={13}>
                        {largebed}
                      </Typography>
                      <IconButton onClick={() => setLargebed(largebed + 1)}>
                        <ControlPointIcon />
                      </IconButton>
                    </Box>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "1.5rem",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Suspense>
                        <BiBed size={iconSize} />
                      </Suspense>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        marginLeft: "1rem",
                      }}
                    >
                      <Typography variant="body1" fontSize={14}>
                        {`Extra-large double bed (Super-king )`}
                      </Typography>
                      <Typography variant="body2" fontSize={12}>
                        181 - 210 cm wide
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        border: "1px solid black",
                        borderRadius: "5px",
                        height: "45px",
                      }}
                    >
                      <IconButton
                        disabled={extraLargebed === 0}
                        onClick={() => setextraLargeBed(extraLargebed - 1)}
                      >
                        <RemoveCircleOutlineIcon />
                      </IconButton>
                      <Typography variant="body1" fontSize={13}>
                        {extraLargebed}
                      </Typography>
                      <IconButton
                        onClick={() => setextraLargeBed(extraLargebed + 1)}
                      >
                        <ControlPointIcon />
                      </IconButton>
                    </Box>
                  </Box>
                </Box>
                <Box
                  onClick={() => setMoreBeds(!moreBeds)}
                  sx={{
                    marginTop: ".5rem",
                    "&:hover": {
                      cursor: "pointer",
                    },
                  }}
                >
                  <Typography
                    variant="body1"
                    fontSize={13}
                    sx={{
                      color: "#0971e5",
                    }}
                  >
                    {moreBeds ? "> Fewer bed options" : "> More bed options"}
                  </Typography>
                </Box>
                {moreBeds && (
                  <>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "1.5rem",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Suspense>
                            <BiBed size={iconSize} />
                          </Suspense>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            marginLeft: "1rem",
                          }}
                        >
                          <Typography variant="body1" fontSize={14}>
                            Bunk Bed
                          </Typography>
                          <Typography variant="body2" fontSize={12}>
                            variable size
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            border: "1px solid black",
                            borderRadius: "5px",
                            height: "45px",
                          }}
                        >
                          <IconButton
                            disabled={bunkbed === 0}
                            onClick={() => setBunkbed(bunkbed - 1)}
                          >
                            <RemoveCircleOutlineIcon />
                          </IconButton>
                          <Typography variant="body1" fontSize={13}>
                            {bunkbed}
                          </Typography>
                          <IconButton onClick={() => setBunkbed(bunkbed + 1)}>
                            <ControlPointIcon />
                          </IconButton>
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "1.5rem",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Suspense>
                            <LuSofa size={iconSize} />
                          </Suspense>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            marginLeft: "1rem",
                          }}
                        >
                          <Typography variant="body1" fontSize={14}>
                            Sofa bed
                          </Typography>
                          <Typography variant="body2" fontSize={12}>
                            Variable size
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            border: "1px solid black",
                            borderRadius: "5px",
                            height: "45px",
                          }}
                        >
                          <IconButton
                            disabled={sofabed === 0}
                            onClick={() => setSofabed(sofabed - 1)}
                          >
                            <RemoveCircleOutlineIcon />
                          </IconButton>
                          <Typography variant="body1" fontSize={13}>
                            {sofabed}
                          </Typography>
                          <IconButton onClick={() => setSofabed(sofabed + 1)}>
                            <ControlPointIcon />
                          </IconButton>
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "1.5rem",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Suspense>
                            <BiBed size={iconSize} />
                          </Suspense>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            marginLeft: "1rem",
                          }}
                        >
                          <Typography variant="body1" fontSize={14}>
                            Futon Mat
                          </Typography>
                          <Typography variant="body2" fontSize={12}>
                            Variable size
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            border: "1px solid black",
                            borderRadius: "5px",
                            height: "45px",
                          }}
                        >
                          <IconButton
                            disabled={futonMet === 0}
                            onClick={() => setFutonMet(futonMet - 1)}
                          >
                            <RemoveCircleOutlineIcon />
                          </IconButton>
                          <Typography variant="body1" fontSize={13}>
                            {futonMet}
                          </Typography>
                          <IconButton onClick={() => setFutonMet(futonMet + 1)}>
                            <ControlPointIcon />
                          </IconButton>
                        </Box>
                      </Box>
                    </Box>
                  </>
                )}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "1rem",
                  }}
                >
                  <Box
                    sx={{
                      width: { xs: "33", sm: "28%", md: "28%" },
                    }}
                  >
                    <Button
                      onClick={() => handleCloseEditRoomDialog()}
                      sx={{
                        width: "100%",
                        textTransform: "none",
                      }}
                    >
                      <Typography
                        variant="body1"
                        sx={{
                          fontSize: { xs: "12px", sm: "15px", md: "15px" },
                        }}
                      >
                        {"< Cancel"}
                      </Typography>
                    </Button>
                  </Box>
                  <Box
                    sx={{
                      width: { xs: "63%", sm: "68%", md: "68%" },
                    }}
                  >
                    <Button
                      onClick={() => handleSaveEditRoomData()}
                      sx={{
                        width: "100%",
                        backgroundColor: "#006ce4",
                        color: "White",
                        textTransform: "none",
                        "&:hover": {
                          backgroundColor: "#006ce4",
                          color: "White",
                        },
                      }}
                    >
                      <Typography
                        variant="body1"
                        sx={{
                          fontSize: { xs: "12px", sm: "15px", md: "15px" },
                        }}
                      >
                        Save
                      </Typography>
                    </Button>
                  </Box>
                </Box>
              </Box>
            </DialogContent>
          </Dialog>
        </Box>
      </Box>
    </Box>,

    // step 9
    <Box sx={{ mt: "1rem" }}>
      <Typography
        variant="h6"
        sx={{ fontSize: "1.25rem", color: "black.main" }}
      >
        <Typography
          variant="caption"
          fontWeight={600}
          sx={{ color: "#E87B25", fontSize: "1.25rem" }}
        >
          Step 9 :&nbsp;
        </Typography>
        Surroundings near your property?
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <TextField
          label="Add Surroundings"
          variant="outlined"
          autoComplete="false"
          type="text"
          name="email"
          value={addSurrounding}
          onChange={handleSurrounding}
          sx={{
            width: "40%",
            mt: "1rem",
            "& .MuiInputBase-root": {
              height: "3.5rem",
              borderRadius: ".625rem",
            },
          }}
        />

        <FormControl sx={{ width: "20%", marginTop: "1.2rem" }}>
          <InputLabel
            id="demo-simple-select-label"
            sx={{ lineHeight: ".7rem" }}
          >
            Units
          </InputLabel>
          <Select
            label="Check in"
            id="demo-simple-select"
            value={distanceUnit}
            onChange={(e) => setDistanceUnit(e.target.value)}
            sx={{ height: "50px" }}
          >
            <MenuItem value="m">metres</MenuItem>
            <MenuItem value="km">kilometres</MenuItem>
            <MenuItem value="mi">miles</MenuItem>
          </Select>
        </FormControl>

        <TextField
          label="Add Distance"
          variant="outlined"
          autoComplete="false"
          type="text"
          name="email"
          value={surroundingDistance}
          onChange={handleSurroundingDistance}
          sx={{
            mt: "1rem",
            width: "30%",
            "& .MuiInputBase-root": {
              height: "3.5rem",
              borderRadius: ".625rem",
            },
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Button
                  startIcon={<AddCircleOutlinedIcon />}
                  aria-label="Toggle visibility"
                  sx={{
                    backgroundColor: "primary.main",
                    color: "White",
                    textTransform: "capitalize",
                    height: "2rem",
                    "&:hover": {
                      backgroundColor: "primary.main",
                      color: "White",
                    },
                  }}
                  onClick={handleSurroundingList}
                >
                  Add
                </Button>
              </InputAdornment>
            ),
          }}
        />
      </Box>
      {surroundingList?.length > 0 && (
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            border: "1px solid gray ",
            mt: 2,
            borderRadius: "15px",
            overflow: "auto",
            marginTop: ".5rem",
            scrollbarWidth: "none",
            msOverflowStyle: "none",
            mb: "6rem",
          }}
        >
          {surroundingList?.map((text, index) => (
            <Box
              key={index}
              sx={{
                width: "calc(48% - 32px)",
                p: 1,
                boxShadow: 2,
                borderRadius: "15px",
                mb: 2,
                mr: index % 2 === 0 ? 2 : 0,
                m: 2,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box>
                {`Name: ${text.Name || text.name}`}
                <Box>{`Distance: ${text.Distance || text.distance}`}</Box>
              </Box>
              <Box onClick={(e) => handleDeleteSurrounding(e, index, text?.id)}>
                <DeleteOutlineIcon />
              </Box>
            </Box>
          ))}
        </Box>
      )}
    </Box>,

    // step 10
    <Box sx={{ mt: "1rem" }}>
      <Typography
        variant="h6"
        sx={{ fontSize: "1.25rem", color: "black.main" }}
      >
        <Typography
          variant="caption"
          fontWeight={600}
          sx={{ color: "#E87B25", fontSize: "1.25rem" }}
        >
          Step 10 :
        </Typography>
        How long can guests stay at your property?
      </Typography>
      <Box sx={{ mt: 2 }}>
        <Typography
          variant="caption"
          fontWeight={600}
          sx={{ fontSize: "1.25rem" }}
        >
          Stay time in days
        </Typography>
        <Box sx={{ width: { xs: "100%", sm: "70%", md: "70%" }, px: 2, mt: 1 }}>
          <Slider
            getAriaLabel={() => "Temperature range"}
            value={range}
            onChange={handleRangeChange}
            valueLabelDisplay="auto"
            getAriaValueText={valuetext}
          />
        </Box>
        <Box sx={{ display: "flex", gap: 2 }}>
          <Box
            sx={{
              display: "flex",
              width: "10rem",
              py: 1,
              px: 2,
              flexDirection: "column",
              borderRadius: "15px",
              border: "1px solid #E7E7E7",
            }}
          >
            <Typography>Minimum</Typography>
            <Typography>{range[0]}</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              width: "10rem",
              py: 1,
              px: 2,
              flexDirection: "column",
              borderRadius: "15px",
              border: "1px solid #E7E7E7",
            }}
          >
            <Typography>Max</Typography>
            <Typography>{range[1]}</Typography>
          </Box>
        </Box>
      </Box>
      {/* <Card>
            <CardContent>Your content for Step 2 goes here</CardContent>
          </Card> */}
    </Box>,

    // step 11
    <Box sx={{ mt: "1rem" }}>
      <Typography
        variant="h6"
        sx={{ fontSize: "1.25rem", color: "black.main" }}
      >
        <Typography
          variant="caption"
          fontWeight={600}
          sx={{ color: "#E87B25", fontSize: "1.25rem" }}
        >
          Step 11 :
        </Typography>
        Update your calendar
        <Typography
          variant="caption"
          fontWeight={400}
          sx={{ fontSize: "1.1rem" }}
        >
          (Click to change status)
        </Typography>
      </Typography>
      <Box>
      <Calendar
        localizer={localizer}
        events={combinedEvents}
        startAccessor="start"
        endAccessor="end"
        selectable
        style={{ height: 500, padding: "1rem 0rem 4.5rem 0rem" }}
        onSelectSlot={handleSelectSlot}
        onSelectEvent={handleDeleteEvent}
        eventPropGetter={eventStyleGetter}
      />
      </Box>
    </Box>,
  ];

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          minHeight: { xs: "auto", sm: "43rem", md: "43rem" },
          width: "60.875rem",
          borderRadius: "1.25rem",
          boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
          overflow: "hidden",
          my: "4rem",
          mb: { xs: "4rem", sm: "4rem", md: "" },
          position: "relative",
        }}
      >
        {/* Close Button  */}
        <IconButton
          //   size="large"
          onClick={() =>
            navigate("/edit", { state: { propertyId: propertyData?.id } })
          }
          sx={{ position: "absolute", top: "1rem", right: "1rem" }}
        >
          <HighlightOffRoundedIcon size="large" />
        </IconButton>

        {/* STEPPER Component */}

        <Box sx={{ p: "2.5rem", height: "100%" }}>
          <Typography
            fontWeight={500}
            variant="h5"
            sx={{ fontSize: "2rem", mb: "1rem" }}
          >
            {/* List Your Property  */}
            {activeStep === steps?.length
              ? "Preview Your property"
              : "List Your Property"}
          </Typography>
          <Box
            sx={{
              width: "100%",
              // border: "1px solid yellow",
              height: "100%",
              // position: "relative",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              {Array?.from({ length: steps?.length })?.map((_, index) => (
                <div
                  key={index}
                  style={{
                    width: "4.125rem",
                    height: "0.25rem",
                    backgroundColor:
                      activeStep >= index ? "#232674" : "#e7e7e7",
                    borderRadius: "3px",
                    // marginRight: "1.5rem",
                  }}
                ></div>
              ))}
            </div>
            {activeStep === steps?.length ? (
              <Box sx={{ height: "90%" }}>
                {/* <Typography sx={{ mt: 2, mb: 1 }}>
                    All steps completed - you&apos;re finished
                  </Typography>
                  <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                    <Box sx={{ flex: "1 1 auto" }} />
                    <Button onClick={handleReset}>Reset</Button>
                  </Box> */}

                {/* preview details */}

                <Box
                  sx={{
                    overflowY: "scroll",
                    // border: "1px solid red",
                    width: "100%",
                    height: "100%",
                    pt: "1rem",
                    pb: "9rem",
                    // position: "relative",
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      height: "7.5rem",
                      position: "absolute",
                      bottom: "3.6rem",
                      right: "0rem",
                      boxShadow: "0px -4px 30px 0px rgba(87, 87, 87, 0.15)",
                      backgroundColor: "white.main",
                      display: "flex",
                      alignItems: "center",
                      zIndex: 5,
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                        px: "2rem",
                      }}
                    >
                      <Box>
                        <Typography
                          fontWeight={400}
                          variant="h4"
                          sx={{ fontSize: "1.5rem", lineHeight: "2rem" }}
                        >
                          Bordeaux Getaway
                          <span style={{ color: "#888" }}> (Villa)</span>
                        </Typography>

                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Typography
                            fontWeight={400}
                            variant="body2"
                            sx={{
                              fontSize: ".875rem",
                              lineHeight: "1.25rem",
                            }}
                          >
                            2 guests
                          </Typography>
                          <Suspense>
                            <BsDot />
                          </Suspense>
                          <Typography
                            fontWeight={400}
                            variant="body2"
                            sx={{
                              fontSize: ".875rem",
                              lineHeight: "1.25rem",
                            }}
                          >
                            1 bedroom
                          </Typography>
                          <Suspense>
                            <BsDot />
                          </Suspense>
                          <Typography
                            fontWeight={400}
                            variant="body2"
                            sx={{
                              fontSize: ".875rem",
                              lineHeight: "1.25rem",
                            }}
                          >
                            1 bed
                          </Typography>
                          <Suspense>
                            <BsDot />
                          </Suspense>
                          <Typography
                            fontWeight={400}
                            variant="body2"
                            sx={{
                              fontSize: ".875rem",
                              lineHeight: "1.25rem",
                            }}
                          >
                            1 bath
                          </Typography>
                        </Box>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "1rem",
                        }}
                      >
                        <Button
                          onClick={handleReset}
                          sx={{ borderRadius: ".625rem" }}
                        >
                          Reset
                        </Button>
                        <Button
                          //   onClick={handleReserve}
                          endIcon={<ArrowForwardIcon />}
                          sx={{
                            fontWeight: "400",
                            fontSize: "1rem",
                            lineHeight: "1.188rem",
                            height: "3.75rem",
                            borderRadius: ".625rem",
                            backgroundColor: "primary.main",
                            color: "white.main",
                            width: "100%",
                            textTransform: "capitalize",
                            // mb:"1.5rem",
                            // zIndex: -1,
                            px: "1rem",
                            "&:hover": {
                              backgroundColor: "primary.main",
                              color: "white.main",
                            },
                          }}
                        >
                          Save changes & Upload Property
                        </Button>
                      </Box>
                    </Box>
                  </Box>

                  {/* Type of Property Section */}
                  <Box sx={{ mb: "1rem" }}>
                    <Typography
                      fontWeight={500}
                      sx={{
                        color: "primary.main",
                        mb: ".5rem",
                        fontSize: "1.2rem",
                      }}
                    >
                      Type of Property
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        height: "6.0625rem",
                        width: "10.25rem",
                        justifyContent: "space-between",
                        borderRadius: "0.9375rem",
                        border: "1px solid #E7E7E7",
                        p: "1rem",
                        cursor: "pointer",
                      }}
                    >
                      <Box sx={{ height: "2rem", width: "2rem" }}>
                        <img
                          src="https://cdn4.iconfinder.com/data/icons/hotel-service-5/300/hotel-512.png"
                          alt="No Preview"
                          style={{
                            height: "100%",
                            width: "100%",
                            objectFit: "cover",
                          }}
                          onError={(e) => {
                            e.onerror = null;
                            e.target.src = require("../../Assets/Images/no-preview.jpeg");
                          }}
                        />
                      </Box>
                      <Typography>Hotel</Typography>
                    </Box>
                  </Box>

                  {/*  About your property Section */}
                  <Box sx={{ mb: "1rem" }}>
                    <Typography
                      fontWeight={500}
                      sx={{
                        color: "primary.main",
                        mb: ".5rem",
                        fontSize: "1.2rem",
                      }}
                    >
                      About your property
                    </Typography>
                    <Typography
                      fontWeight={600}
                      sx={{ fontSize: "1rem", mb: ".2rem" }}
                    >
                      Bordeaux Getaway &nbsp;
                      <Typography
                        variant="caption"
                        fontWeight={400}
                        sx={{
                          color: "secondaryText.main",
                          fontSize: "1rem",
                        }}
                      >
                        (Villa)
                      </Typography>
                    </Typography>
                    <Typography>
                      Come and stay in this superb duplex T2, in the heart of
                      the historic center of Bordeaux. Spacious and bright, in a
                      real Bordeaux building in exposed stone, you will enjoy
                      all the charms of the city thanks to its ideal location.
                      Close to many shops, bars and restaurants, you can access
                      the apartment by tram A and C and bus routes 27 and 44
                    </Typography>
                  </Box>

                  {/* Your address with location Section */}
                  <Box sx={{ mb: "1rem" }}>
                    <Typography
                      fontWeight={500}
                      sx={{
                        color: "primary.main",
                        mb: ".5rem",
                        fontSize: "1.2rem",
                      }}
                    >
                      Your address with location
                    </Typography>
                    <Typography
                      fontWeight={700}
                      sx={{ color: "black.main", fontSize: ".9rem" }}
                    >
                      64 Place Napoléon
                    </Typography>
                    <Typography
                      fontWeight={700}
                      sx={{ color: "black.main", fontSize: ".9rem" }}
                    >
                      LAVAL, Pays de la Loire(PL), 53000
                    </Typography>

                    <iframe
                      title="Google Map"
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15257.123456789012!2d77.4166842!3d23.2136667!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396c71c70b5f872f%3A0xa33d9c7ad028b524!2sIndra%20Puri%2C%20Bhopal%2C%20Madhya%20Pradesh%2C%20India!5e0!3m2!1sen!2sus!4v1617234560504!5m2!1sen!2sus"
                      style={{
                        width: "100%",
                        height: "20rem",
                        border: "0px",
                        // marginBottom: "3rem",
                        marginTop: ".5rem",
                      }}
                      loading="lazy"
                      allowfullscreen
                    ></iframe>
                  </Box>

                  {/*  Property layout Section */}
                  <Box sx={{ mb: "1rem" }}>
                    <Typography
                      fontWeight={500}
                      sx={{
                        color: "primary.main",
                        mb: ".5rem",
                        fontSize: "1.2rem",
                      }}
                    >
                      Property layout
                    </Typography>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography
                        fontWeight={400}
                        variant="body2"
                        sx={{
                          fontSize: "1.2rem",
                          lineHeight: "1.25rem",
                        }}
                      >
                        2 guests
                      </Typography>
                      <Suspense>
                        <BsDot />
                      </Suspense>
                      <Typography
                        fontWeight={400}
                        variant="body2"
                        sx={{
                          fontSize: "1.2rem",
                          lineHeight: "1.25rem",
                        }}
                      >
                        1 bedroom
                      </Typography>
                      <Suspense>
                        <BsDot />
                      </Suspense>
                      <Typography
                        fontWeight={400}
                        variant="body2"
                        sx={{
                          fontSize: "1.2rem",
                          lineHeight: "1.25rem",
                        }}
                      >
                        1 bed
                      </Typography>
                      <Suspense>
                        <BsDot />
                      </Suspense>
                      <Typography
                        fontWeight={400}
                        variant="body2"
                        sx={{
                          fontSize: "1.2rem",
                          lineHeight: "1.25rem",
                        }}
                      >
                        1 bath
                      </Typography>
                    </Box>
                  </Box>

                  {/* Amenities Section*/}
                  <Box sx={{ mb: "1rem" }}>
                    <Typography
                      fontWeight={500}
                      sx={{
                        color: "primary.main",
                        mb: ".5rem",
                        fontSize: "1.2rem",
                      }}
                    >
                      Amenities
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        // border: "1px solid red",
                        alignItems: "center",
                      }}
                    >
                      <Box sx={{ width: "1rem", height: "1rem" }}>
                        <img
                          src="https://cdn5.vectorstock.com/i/1000x1000/28/89/smoking-icon-vector-25322889.jpg"
                          alt="No Preview"
                          style={{
                            height: "100%",
                            width: "100%",
                            objectFit: "cover",
                          }}
                          onError={(e) => {
                            e.onerror = null;
                            e.target.src = require("../../Assets/Images/no-preview.jpeg");
                          }}
                        />
                      </Box>
                      <Typography
                        fontWeight={400}
                        variant="body2"
                        sx={{
                          fontSize: "1rem",
                          letterSpacing: "-0.019rem",
                          mt: 0.3,
                          ml: 1.5,
                        }}
                      >
                        Smoking
                      </Typography>
                    </Box>
                  </Box>

                  {/* House Rules Section */}
                  <Box sx={{ mb: "1rem" }}>
                    <Typography
                      fontWeight={500}
                      sx={{
                        color: "primary.main",
                        mb: ".5rem",
                        fontSize: "1.2rem",
                      }}
                    >
                      House Rules
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        // border: "1px solid red",
                        alignItems: "center",
                      }}
                    >
                      <Box sx={{ width: "1rem", height: "1rem" }}>
                        <img
                          src="https://cdn5.vectorstock.com/i/1000x1000/28/89/smoking-icon-vector-25322889.jpg"
                          alt="No Preview"
                          style={{
                            height: "100%",
                            width: "100%",
                            objectFit: "cover",
                          }}
                          onError={(e) => {
                            e.onerror = null;
                            e.target.src = require("../../Assets/Images/no-preview.jpeg");
                          }}
                        />
                      </Box>
                      <Typography
                        fontWeight={400}
                        variant="body2"
                        sx={{
                          fontSize: "1rem",
                          letterSpacing: "-0.019rem",
                          mt: 0.3,
                          ml: 1.5,
                        }}
                      >
                        Swiming pool
                      </Typography>
                    </Box>
                  </Box>

                  {/* Property Photos and Videos Section */}
                  <Box sx={{ mb: "1rem" }}>
                    <Typography
                      fontWeight={500}
                      sx={{
                        color: "primary.main",
                        mb: ".5rem",
                        fontSize: "1.2rem",
                      }}
                    >
                      Property Photos and Videos
                    </Typography>
                    {/* Image Container */}
                    <Box>
                      <Box
                        gap={1}
                        sx={{
                          //   width: "70%",
                          // border: "1px solid blue",
                          // display: "flex",
                          display: { xs: "none", sm: "flex", md: "flex" },
                          borderRadius: ".7rem",
                          overflow: "hidden",
                          height: "19.688rem",
                          position: "relative",
                          // mb: "2rem",
                        }}
                      >
                        <Button
                          onClick={handleOpen}
                          variant="contained"
                          startIcon={<AddIcon />}
                          sx={{
                            position: "absolute",
                            right: "1rem",
                            bottom: "1rem",
                            backgroundColor: "white.main",
                            color: "black.main",
                            textTransform: "inherit",
                            px: 2,
                            zIndex: 1,
                            "&:hover": {
                              backgroundColor: "#e2ddd3",
                              color: "black.main",
                            },
                          }}
                        >
                          {/* {productDetails?.property_images?.length > 5
                              ? productDetails?.property_images?.length - 5
                              : 0} */}
                          Photos
                        </Button>
                        <Box
                          sx={{
                            width: "50%",
                            // border: "1px solid green",
                            height: "100%",
                            overflow: "hidden",
                          }}
                        >
                          <img
                            className="zoom"
                            // src={`${process.env.REACT_APP_API}${
                            //   productDetails?.property_images?.at(0)?.img
                            // }`}
                            src={productItem?.at(0)?.image}
                            alt="No Preview"
                            style={{
                              height: "100%",
                              width: "100%",
                              objectFit: "fill",
                            }}
                            onError={(e) => {
                              e.onerror = null;
                              e.target.src = require("../../Assets/Images/no-preview.jpeg");
                            }}
                          />
                        </Box>

                        <Box
                          gap={1}
                          sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            width: "50%",
                            // border: "1px solid green",
                            height: "100%",
                          }}
                        >
                          <Box
                            sx={{
                              width: "49%",
                              height: "50%",
                              overflow: "hidden",
                            }}
                          >
                            <img
                              className="zoom"
                              // src={`${process.env.REACT_APP_API}${
                              //   productDetails?.property_images?.at(1)?.img
                              // }`}
                              src={productItem?.at(1)?.image}
                              alt="No Preview"
                              style={{
                                height: "100%",
                                width: "100%",
                                objectFit: "fill",
                              }}
                              onError={(e) => {
                                e.onerror = null;
                                e.target.src = require("../../Assets/Images/no-preview.jpeg");
                              }}
                            />
                          </Box>
                          <Box
                            sx={{
                              width: "49%",
                              height: "50%",
                              overflow: "hidden",
                            }}
                          >
                            <img
                              className="zoom"
                              src={productItem?.at(2)?.image}
                              alt="No Preview"
                              style={{
                                height: "100%",
                                width: "100%",
                                objectFit: "fill",
                              }}
                              onError={(e) => {
                                e.onerror = null;
                                e.target.src = require("../../Assets/Images/no-preview.jpeg");
                              }}
                            />
                          </Box>
                          <Box
                            sx={{
                              width: "49%",
                              height: "50%",
                              overflow: "hidden",
                            }}
                          >
                            <img
                              className="zoom"
                              src={productItem?.at(3)?.image}
                              alt="No Preview"
                              style={{
                                height: "100%",
                                width: "100%",
                                objectFit: "fill",
                              }}
                              onError={(e) => {
                                e.onerror = null;
                                e.target.src = require("../../Assets/Images/no-preview.jpeg");
                              }}
                            />
                          </Box>
                          <Box
                            sx={{
                              width: "49%",
                              height: "50%",
                              overflow: "hidden",
                            }}
                          >
                            <img
                              className="zoom"
                              src={productItem?.at(4)?.image}
                              alt="No Preview"
                              style={{
                                height: "100%",
                                width: "100%",
                                objectFit: "fill",
                              }}
                              onError={(e) => {
                                e.onerror = null;
                                e.target.src = require("../../Assets/Images/no-preview.jpeg");
                              }}
                            />
                          </Box>
                        </Box>
                      </Box>

                      {/* <Box
                              sx={{
                                display: {
                                  xs: "block",
                                  sm: "none",
                                  md: "none",
                                },
                                width: "100%",
                                // border: "1px solid black",
                                height: "15rem",
                              }}
                            >
                              <Swiper
                                // autoplay={{
                                //   delay: 3000,
                                //   disableOnInteraction: false,
                                // }}
                                pagination={{
                                  type: "fraction",
                                  color: "#FFF",
                                }}
                                // install Swiper modules
                                modules={[Navigation, Pagination, Scrollbar]}
                                spaceBetween={xs ? 5 : sm ? 5 : 30}
                                slidesPerView={xs ? 1 : sm ? 1 : 1}
                                loop={true}
                                // grabCursor={true}
                                // pagination={{ clickable: true }}
                                //   scrollbar={{ draggable: true }}
                                //   onSwiper={(swiper) => console.log(swiper)}
                                // onSlideChange={() => console.log("slide change")}

                                style={{
                                  // paddingTop:"2rem",
                                  // height:"90%",
                                  // border:"1px solid red",
                                  "--swiper-pagination-color": "#FFF",
                                  "--swiper-pagination-bullet-inactive-color":
                                    "#FFF",
                                  "--swiper-pagination-bullet-inactive-opacity":
                                    "1",
                                  "--swiper-pagination-bullet-size": "10px",
                                  "--swiper-pagination-bullet-horizontal-gap":
                                    "7px",
                                  "--swiper-pagination-bottom": xs
                                    ? "-5px"
                                    : sm
                                    ? "10px"
                                    : "10px",
                                  "--swiper-pagination-fraction-color": "#FFF",
                                }}
                              >
                                {productItem?.map((ele, i) => (
                                  <SwiperSlide key={i}>
                                    <Box
                                      sx={{
                                        width: "100%",
                                        // border: "1px solid green",
                                        height: "90%",
                                        overflow: "hidden",
                                      }}
                                    >
                                      <img
                                        src={ele?.image}
                                        alt="No Preview"
                                        style={{
                                          height: "100%",
                                          width: "100%",
                                          objectFit: "cover",
                                        }}
                                        onError={(e) => {
                                          e.onerror = null;
                                          e.target.src = require("../../Assets/Images/no-preview.jpeg");
                                        }}
                                      />
                                    </Box>
                                  </SwiperSlide>
                                ))}
                              </Swiper>
                            </Box> */}
                    </Box>
                  </Box>

                  {/* Prising Section */}
                  <Box sx={{ mb: "1rem" }}>
                    <Typography
                      fontWeight={500}
                      sx={{
                        color: "primary.main",
                        mb: ".5rem",
                        fontSize: "1.2rem",
                      }}
                    >
                      Pricing
                    </Typography>
                    <Box sx={{ mt: "1rem" }}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 2,
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <Typography
                            variant="h6"
                            fontWeight={500}
                            sx={{ fontSize: "1rem", mb: ".5rem" }}
                          >
                            Daily
                          </Typography>
                          {/* jhgfdx */}
                          <TextField
                            // label="Property name"
                            variant="outlined"
                            autoComplete="false"
                            type="number"
                            name="email"
                            required
                            value={dailyPrice}
                            onChange={(e) => setDailyPrice(e.target.value)}
                            sx={{
                              width: "15rem",
                              "& .MuiInputBase-root": {
                                height: "3.5rem",
                                borderRadius: ".625rem",
                              },
                            }}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      flexDirection: "row",
                                    }}
                                  >
                                    <EuroSymbolIcon sx={{ color: "#E87B25" }} />
                                    {/* Icon for the start position */}
                                    <Divider
                                      orientation="vertical"
                                      flexItem
                                      sx={{ mx: ".3rem" }}
                                    />
                                    <CurrencyRupeeIcon />
                                  </Box>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <Typography
                            variant="h6"
                            fontWeight={500}
                            sx={{ fontSize: "1rem", mb: ".5rem" }}
                          >
                            Weekly
                          </Typography>
                          {/* jhgfdx */}
                          <TextField
                            // label="Property name"
                            variant="outlined"
                            autoComplete="false"
                            type="number"
                            name="email"
                            required
                            value={dailyWeekly}
                            onChange={(e) => setDailyWeekly(e.target.value)}
                            sx={{
                              width: "15rem",
                              "& .MuiInputBase-root": {
                                height: "3.5rem",
                                borderRadius: ".625rem",
                              },
                            }}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      flexDirection: "row",
                                    }}
                                  >
                                    <EuroSymbolIcon sx={{ color: "#E87B25" }} />
                                    {/* Icon for the start position */}
                                    <Divider
                                      orientation="vertical"
                                      flexItem
                                      sx={{ mx: ".3rem" }}
                                    />
                                    <CurrencyRupeeIcon />
                                  </Box>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Box>
                      </Box>
                    </Box>
                  </Box>

                  {/* Other Details Section*/}
                  <Box sx={{ mb: "1rem" }}>
                    <Typography
                      fontWeight={500}
                      sx={{
                        color: "primary.main",
                        mb: ".5rem",
                        fontSize: "1.2rem",
                      }}
                    >
                      Other Details
                    </Typography>

                    <Box>{/* Put the calender here */}</Box>
                  </Box>

                  {/* Days travelers can stay Section */}
                  <Box sx={{ mb: "1rem" }}>
                    <Typography
                      fontWeight={500}
                      sx={{
                        color: "black.main",
                        mb: ".5rem",
                        fontSize: "1rem",
                      }}
                    >
                      Days travelers can stay
                    </Typography>
                    <Typography
                      variant="h4"
                      fontWeight={600}
                      sx={{ fontSize: "1.4rem", color: "black.main" }}
                    >
                      2 - 30 days
                    </Typography>
                  </Box>
                </Box>
              </Box>
            ) : (
              <Box>
                {stepsContent[activeStep]}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    pt: 2,
                    position: {
                      xs: "static",
                      sm: "absolute",
                      md: "absolute",
                    },
                    bottom: { xs: "0", sm: "3.5rem", md: "3.5rem" },
                    right: "2rem",
                    bottom: "2rem",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    // endIcon={<ArrowForwardIcon />}
                    onClick={handleButtonClick}
                    sx={{
                      ml: "2rem",
                      textTransform: "capitalize",
                      backgroundColor: "blue",
                      color: "white",
                      width: "12.6rem",
                      height: "3.6rem",
                      fontSize: "1rem",
                      fontWeight: "400",
                      borderRadius: ".625rem",
                      "&:hover": {
                        color: "white.main",
                        backgroundColor: "blue",
                      },
                    }}
                  >
                    Save
                  </Button>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default EditProperty;
